import { instance } from '@/store/api-instance'
import { IContractsResponse } from '@/store/modules/contracts/interfaces/api/IContractsResponse'
import { AxiosResponse } from 'axios'

export const contractsAPI = {
  async fetchContracts (): Promise<IContractsResponse> {
    const response: AxiosResponse = await instance.get<IContractsResponse>('contracts')
    return response.data
  }
}
