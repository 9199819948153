
import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { shipmentPlaces, orderTransportTypes } from '@/utils/mockAPIresponse'
import { IOrder, IOrderListRequest } from '../interfaces/orders.interface'

async function getOrdersAPI (dto: IOrderListRequest) {
  const response: AxiosResponse = await instance.get<IOrder>('orders', { params: dto })
  return response.data
}

async function getShipmentPlacesAPI () {
  return await JSON.parse(shipmentPlaces)
}

async function getOrderTransportTypesAPI () {
  return await JSON.parse(orderTransportTypes)
}

export { getOrdersAPI, getShipmentPlacesAPI, getOrderTransportTypesAPI }
