import gql from 'graphql-tag'

export const fetchGenerateTokenMutation = gql(`
mutation($email: String!, $password: String!) {
  generateCustomerToken(
    email: $email
    password: $password
  ) {
    token
  }
}
`)
