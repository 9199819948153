
import { defineComponent } from 'vue'
import { Carousel, Pagination, Slide } from 'vue3-carousel'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'
import refreshAfterChangeLanguage from '@/mixins/refreshAfterChangeLanguage'

import 'vue3-carousel/dist/carousel.css'

export default defineComponent({
  name: 'TutorialModal',

  components: {
    Carousel,
    Pagination,
    Slide
  },

  mixins: [refreshAfterChangeLanguage],

  data () {
    return {
      currentSlide: 0
    }
  },

  props: {
    closeModal: {
      type: Function,
      default: () => {
        /* */
      }
    }
  },

  created () {
    this.refresh()
  },

  computed: {
    helpPagesStore () {
      return this.$store.state.helpPages
    },

    sortedHelpPages () {
      return [...this.helpPagesStore.helpPages].sort((a, b) => (a.page || 0) - (b.page || 0))
    }
  },

  methods: {
    refresh () {
      this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },

    handleInit () {
      this.currentSlide = 0
    },

    handleSlideStart ({ slidingToIndex }: any) {
      this.currentSlide = slidingToIndex
    },

    done () {
      const userInfo = this.$store.state.auth.user
      if (userInfo.firstLogin === true) {
        userInfo.firstLogin = false
        this.$store.commit(AuthMutationTypes.SET_USER_INFO, userInfo)
        this.$store.dispatch(AuthActionTypes.SET_FIRST_LOGIN, {
          UID: userInfo.UID,
          firstLogin: false
        })
      }
      this.closeModal()
    },

    back () {
      (this.$refs.tutorialCarousel as any).prev()
    },

    next () {
      (this.$refs.tutorialCarousel as any).next()
    }
  }
})
