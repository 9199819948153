import { defineComponent } from 'vue'
import type { ComponentPublicInstance } from 'vue'
import loader from '@/utils/loader'

let loadingCount = 0
let loadingPaused = false
let loadingIsShown = false

export default defineComponent({
  beforeCreate () {
    installLoadingIndicators(this)
    installSafeMethods(this)
  }
}) as ThisType<ComponentPublicInstance>

export { setLoadingPaused }

const isEmpty = (value: any) => {
  return (
    value == null ||
    (typeof value === 'object' && Object.keys(value).length === 0) ||
    (typeof value === 'string' && value.trim().length === 0)
  )
}

function toggleLoading (isLoading: boolean) {
  if (isLoading) {
    loadingCount++
    showOrHideLoadingOverlay()
  } else {
    loadingCount--
    showOrHideLoadingOverlay()
  }
}

function setLoadingPaused (newLoadingPausedStatus: boolean) {
  loadingPaused = newLoadingPausedStatus
  showOrHideLoadingOverlay()
}

function showOrHideLoadingOverlay () {
  if (loadingIsShown) {
    if (loadingCount <= 0 || loadingPaused) {
      loadingIsShown = false
      loader.showLoader(false)
    }
  } else {
    if (loadingCount > 0 && !loadingPaused) {
      loadingIsShown = true
      loader.showLoader(true)
    }
  }
}

function installLoadingIndicators (componentInstance: ComponentPublicInstance) {
  const loadingIndicators = normalizeLoadingIndicatorOptions(componentInstance)
  if (!loadingIndicators) return
  if (isEmpty(componentInstance.$options.methods)) return
  const methods = componentInstance.$options.methods
  for (const smKey in methods) {
    let li = loadingIndicators[smKey]
    if (li === undefined) li = loadingIndicators['*']
    if (li && methods[smKey].constructor.name === 'AsyncFunction') {
      componentInstance.$options.methods[smKey] = makeFunctionWithLoading(methods[smKey])
    }
  }
}

function normalizeLoadingIndicatorOptions (
  componentInstance: ComponentPublicInstance
): Record<string, string | boolean> | null {
  let loadingIndicators = componentInstance.$options.loadingIndicators
  if (!(typeof loadingIndicators === 'object' || typeof loadingIndicators === 'boolean')) return null
  if (typeof loadingIndicators === 'boolean') loadingIndicators = { '*': loadingIndicators }
  return loadingIndicators
}

function installSafeMethods (componentInstance: ComponentPublicInstance) {
  const sms = { ...componentInstance.$options.safeMethods }

  if (!isEmpty(sms)) {
    if (componentInstance.$options.methods === undefined) {
      componentInstance.$options.methods = {}
    }
    for (const smKey in sms) {
      componentInstance.$options.methods[smKey] = makeSafeFunction(sms[smKey].bind(componentInstance))
    }
  }

  if (!isEmpty(componentInstance.$options.mixins)) {
    for (const mixin of componentInstance.$options.mixins || []) {
      if (isEmpty(mixin.safeMethods)) continue

      if (componentInstance.$options.methods === undefined) componentInstance.$options.methods = {}

      for (const smKey in mixin.safeMethods) {
        componentInstance.$options.methods[smKey] = makeSafeFunction(mixin.safeMethods[smKey].bind(componentInstance))
      }
    }
  }
}

function makeSafeFunction (functionToWrap: any) {
  return async function (this: any, ...args: any[]) {
    toggleLoading(true)
    try {
      args = Array.prototype.slice.call(args)
      const result = await functionToWrap.apply(this, args)
      return result
    } catch (error: any) {
      if (error.isSafeAbortError) return
      throw error
    } finally {
      toggleLoading(false)
    }
  }
}

function makeFunctionWithLoading (functionToWrap: any) {
  return async function (this: any, ...args: any[]) {
    toggleLoading(true)
    try {
      return await functionToWrap.apply(this, args)
    } finally {
      toggleLoading(false)
    }
  }
}
