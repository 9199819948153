import gql from 'graphql-tag'

export const reOrderFromHistory = gql(`
mutation($orderNumber: String!) {
    reorderItems(
      orderNumber: $orderNumber
    ) {
      cart {
        id
        items {
          uid
          product {
              sku
          }
          quantity
          prices {
          price {
            value
          }
        }
      }
    }
    userInputErrors{
      code
      message
      path
    }
  }
}
`)
