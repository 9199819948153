import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { IInvoicesOlsaRequest } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaRequest'
import { IInvoicesOlsaResponse } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaResponse'
import { ISelectedInvoiceOlsaRequest } from '../interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaRequest'
import { ISelectedInvoiceOlsaResponse } from '../interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaResponse'

export const invoicesOlsaAPI = {
  async getInvoicesOlsa (dto: IInvoicesOlsaRequest): Promise<IInvoicesOlsaResponse> {
    const response: AxiosResponse<IInvoicesOlsaResponse> = await instance.post('invoiceolsa/getdatarchive', dto)
    return response.data
  },
  async getSelectedInvoiceOlsa (dto: ISelectedInvoiceOlsaRequest): Promise<ISelectedInvoiceOlsaResponse> {
    const response: AxiosResponse<ISelectedInvoiceOlsaResponse> = await instance.post('invoiceolsa/getdocument', dto)
    return response.data
  }
}
