import { ActionContext } from 'vuex'
import { IRootState } from '@/store'
import { IPortalHardCodeLink } from './IPortalHardCodeLink'

export enum LinksMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_ALL_LINKS = 'SET_ALL_LINKS',
  SET_PORTAL_HARD_CODE_LINK = 'SET_PORTAL_HARD_CODE_LINK'
}

export enum LinksActionType {
  FETCH_ALL_LINKS = 'FETCH_ALL_LINKS',
  FETCH_PORTAL_HARD_CODE_LINK = 'FETCH_PORTAL_HARD_CODE_LINK'
}

export interface ILinks {
  name: string
  url: string
  locationId: string
  [key: string]: any
}

export interface IFetchLinksRequest {
  limit?: number
  offset?: number
  market?: string
  userType?: string
  language?: string
  sortType?: string
}

export interface IFetchPortalHardCodeLinkRequest {
  Limit?: number,
  Offset?: number,
  searchText?: string,
  isLoggedIn?: boolean,
  locationID?: string

}
export interface IFetchLinksResponse {
  total: number
  offset: number
  limit: number
  results: Array<ILinks>
}

export interface IFetchPortalHardCodeLinkResponse {
  total: number
  offset: number
  limit: number
  results: IPortalHardCodeLink[]
}

export interface ILinksState {
  links: Array<ILinks>
  loading: boolean
}
export interface IPortalHardCodeLinkState {
  portalHardCodeLink: IFetchPortalHardCodeLinkResponse
  search: string
  loading: boolean
}

export interface ILinksMutations<S = ILinksState> {
  [LinksMutationType.SET_LOADING](state: S, payload: boolean): void
  [LinksMutationType.SET_ALL_LINKS](state: S, payload: Array<ILinks>): void
}

export type AugmentedLinksActionsType = {
  commit<K extends keyof ILinksMutations>(
    key: K,
    payload: Parameters<ILinksMutations[K]>[1]
  ): ReturnType<ILinksMutations[K]>
} & Omit<ActionContext<ILinksState, IRootState>, 'commit'>

export interface ILinksActions {
  [LinksActionType.FETCH_ALL_LINKS]({ commit }: AugmentedLinksActionsType, payload: IFetchLinksRequest): void
}

export interface IPortalHardCodeLinkMutations<S = IPortalHardCodeLinkState> {
  [LinksMutationType.SET_LOADING](state: S, payload: boolean): void

  [LinksMutationType.SET_PORTAL_HARD_CODE_LINK](state: S, payload: IFetchPortalHardCodeLinkResponse): void
}

export type AugmentedPortalHardCodeLinkActionType = {
  commit<K extends keyof IPortalHardCodeLinkMutations>(
    key: K,
    payload: Parameters<IPortalHardCodeLinkMutations[K]>[1]
  ): ReturnType<IPortalHardCodeLinkMutations[K]>
} & Omit<ActionContext<IPortalHardCodeLinkState, IRootState>, 'commit'>

export interface IPortalHardCodeLinkActions {
  [LinksActionType.FETCH_PORTAL_HARD_CODE_LINK](
    { commit }: AugmentedPortalHardCodeLinkActionType,
    payload: IFetchPortalHardCodeLinkRequest
  ): void
}
