
import { defineComponent } from 'vue'
import { setI18nLanguage } from '@/i18n'
import { LanguagesType } from '@/types'
import { constants } from '@/constants'

export default defineComponent({
  name: 'BeforeLoginLayout',

  data: () => ({
    activeLanguage: (localStorage.getItem('lang')) as LanguagesType,
    theme: constants.application.theme
  }),

  inject: ['isPeroni'],

  computed: {
    user () {
      return this.$store.state.auth.user
    },

    languages () {
      if (this.user.country === 'CZE' || window.location.hostname.includes('.cz')) {
        return ['cs', 'en']
      }

      if (this.user.country === 'SVK' || window.location.hostname.includes('.sk')) {
        return ['sk', 'en']
      }

      if (constants.application.theme === 'peroni') {
        return ['it', 'en']
      }

      return ['en', this.activeLanguage].filter((x, index, self) => x != null && self.indexOf(x) === index)
    }
  },

  methods: {
    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    }
  }
})
