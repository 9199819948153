import { defineComponent } from 'vue'

export default defineComponent({
  computed: {
    locale () {
      return this.$i18n.locale as string
    }
  },

  watch: {
    locale (value, oldValue) {
      const that = this as any
      if (value !== oldValue) {
        if (that.$refresh) that.$refresh()
        if (that.refresh) that.refresh()
      }
    }
  }
})
