import { AxiosResponse } from 'axios'
import { shipmentPlaces, orderStatus, orderStatusIT } from '../../../../utils/mockAPIresponse'
import { IOrderTypeResponse, IOrderStatusResponse } from '../interface/IOrderReview'
import { instance } from '@/store/api-instance'
import { IOrderStatusCZ } from '../interface/IOrderStatus'

async function getShipmentPlacesAPI () {
  return await JSON.parse(shipmentPlaces)
}

async function getOrderTransportTypesAPI () {
  const response: AxiosResponse = await instance.get<IOrderTypeResponse>('ordertypes')
  return response?.data
}

async function getStatusForCZ () {
  const response: AxiosResponse = await instance.get<IOrderStatusCZ>('orders/GetOrderStatus')
  return response?.data
}

async function getOrderStatusAPI () {
  return await JSON.parse(orderStatus)
}
async function getOrderStatusITAPI () {
  const response: AxiosResponse = await instance.get<IOrderStatusResponse>('orderstatus')
  return response?.data
}
async function getOrderDeliveryStatusITAPI () {
  return await JSON.parse(orderStatusIT)
}

export {
  getShipmentPlacesAPI,
  getOrderTransportTypesAPI,
  getOrderStatusAPI,
  getOrderStatusITAPI,
  getStatusForCZ,
  getOrderDeliveryStatusITAPI
}
