
import { defineComponent } from 'vue'
import { mapGetters, mapState } from 'vuex'
import ResizeObserver from '@/components/ResizeObserver.vue'
import HelpActionButton from '@/components/HelpActionButton.vue'
import TutorialModal from '@/components/TutorialModal.vue'
import MessageModal from '@/components/common/MessageModal.vue'
import BannersComponent from '@/components/BannersComponent.vue'
import UserToolbar from '@/components/UserToolbar.vue'
import { AuthActionTypes } from '@/store/modules/auth/interfaces/action-types'
import { LinksActionType } from '@/store/modules/links/interfaces'
import { setI18nLanguage } from '@/i18n'
import Error401Page from './pages/Error401Page.vue'
import { constants } from '@/constants'
import BeforeLoginLayout from '@/components/layout/BeforeLoginLayout.vue'
import { LanguagesType } from '@/types'
import { HelpPagesActionType } from '@/store/modules/help-pages/interfaces'
import AppGlobalModal from '@/components/app/AppGlobalModal.vue'
import ModalComponent from './components/ModalComponent.vue'
import { ArticlesMutationType } from './store/modules/articles/interfaces/mutation-type'
import { FilesMutationType } from './store/modules/files/interfaces/mutation-type'
import { AuthMutationTypes } from '@/store/modules/auth/interfaces/mutation-types'
import { RolePermissionDetails } from './store/modules/users/interfaces/IUser'
import { IAuthState } from './store/modules/auth/IAuthState'
import { IFetchSearchResponse } from '@/store/modules/main/intefaces/fetchSearch/IFetchSearchResponse'
import { IPortalHardCodeLink } from './store/modules/links/interfaces/IPortalHardCodeLink'
import { getValueByLabel } from './utils/permissions'

export default defineComponent({
  name: 'App',

  components: {
    HelpActionButton,
    TutorialModal,
    Error401Page,
    MessageModal,
    BannersComponent,
    UserToolbar,
    BeforeLoginLayout,
    ModalComponent,
    AppGlobalModal,
    ResizeObserver
  },
  data: () => ({
    gigICode: '',
    gigIvToken: '',
    isPortalChrome: true,
    theme: constants.application.theme,
    isLoaded: false,
    openModal: false,
    message: '',
    subMessage: '',
    searchValue: '',
    searchValues: [] as IFetchSearchResponse[],
    isErrorModal: true,
    copyRightMsg: `© Copyright Asahi CE & Europe Services s.r.o., ${new Date().getFullYear()}`,
    activeLanguage: (localStorage.getItem('lang')) as LanguagesType,
    clientWidth: 0,
    dictionary: {
      en: {
        createAnOrder: 'CREATE AN ORDER',
        createAnOrderIt: 'Create An Order',
        cart: 'MY CART',
        otherLinks: 'Other links',
        legalClause: 'Legal clause',
        backUp: 'Back up',
        menu: 'Menu',
        tutorial: 'Tutorial',
        search: 'Search',
        home: 'Homepage',
        profile: 'My Profile',
        contracts: 'Contracts',
        orders: 'Orders',
        history: 'History',
        savedOrders: 'Saved Orders',
        suggestedCart: 'Suggested Cart',
        catalog: 'Catalogue',
        products: 'Product Overview',
        favorites: 'My Favorites',
        favoritesMenu: 'MY FAVORITES',
        reporting: 'Reporting',
        myDocuments: 'My documents',
        marketingMaterials: 'Marketing Materials',
        news: 'News and Articles',
        newsEvents: 'News and Events',
        aboutPeroni: 'About Peroni',
        support: 'Support',
        faq: 'FAQ',
        knowledgeBase: 'Knowledge Base',
        supportTickets: 'Support Tickets',
        eLearning: 'Academy',
        skELearning: 'Academy',
        pubMenu: 'Menu Creator',
        skPubMenu: 'Menu Creator',
        emptiesManagement: 'Empties Management',
        contacts: 'Contacts',
        about: 'About us',
        privacyPolicy: 'Privacy Policy',
        cookies: 'Cookies and personal data protection',
        brands: 'Brands',
        productCode: 'Product code',
        createNewUser: 'Create New User',
        trainingLearning: 'Training & Learning',
        Unit: 'Unit',
        actions: {
          edit: 'My Profile',
          logout: 'Logout'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Login',
        notificationsEmpty: 'No notifications',
        termsOfUse: 'Terms of use',
        corporateInformation: 'Corporate Information',
        corporateGovernance: 'Corporate Governance',
        goToCart: 'Go To Cart',
        removeItem: 'Remove Item'
      },
      cs: {
        createAnOrder: 'Vytvořit objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavní stránka',
        profile: 'Můj profil',
        contracts: 'Přehled smluv',
        orders: 'Objednávky',
        history: 'Historie objednávek',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Návrh objednávky',
        catalog: 'Katalog',
        products: 'Přehled výrobků',
        favorites: 'Moje oblíbené',
        favoritesMenu: 'Moje oblíbené',
        reporting: 'Přehled odběrů',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pro Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovědi',
        knowledgeBase: 'O portálu',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akademie První u zdroje',
        pubMenu: 'Prazdroj menu',
        otherLinks: 'Další odkazy',
        legalClause: 'Právní doložka',
        backUp: 'Zpět nahoru',
        menu: 'Menu',
        tutorial: 'Průvodce',
        search: 'Vyhledat',
        contacts: 'Kontakty',
        about: 'O nás',
        privacyPolicy: 'Ochrana soukromí',
        cookies: 'Cookies a ochrana osobních údajů',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvořit nového uživatele',
        Unit: 'Balení',
        actions: {
          edit: 'Profil',
          logout: 'Odhlásit'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          de: 'Deutsch',
          it: 'Italiano',
          sk: 'Slovensky'
        },
        login: 'Přihlásit se',
        notificationsEmpty: 'Žádné notifikace',
        termsOfUse: 'Podmínky použití',
        goToCart: 'Košík',
        removeItem: 'Odstranit'
      },
      sk: {
        createAnOrder: 'Vytvoriť objednávku',
        administration: 'Administrace',
        cart: 'Košík',
        home: 'Hlavná stránka',
        profile: 'Môj profil',
        contracts: 'Prehľad zmlúv',
        orders: 'Objednávky',
        history: 'História objednávok',
        savedOrders: 'Uložené objednávky',
        suggestedCart: 'Odporúčaný tovar',
        catalog: 'Katalóg',
        products: 'Prehľad výrobkov',
        favorites: 'Moje obľúbené',
        favoritesMenu: 'Moje obľúbené',
        reporting: 'Prehľad odberov',
        myDocuments: 'Moje dokumenty',
        marketingMaterials: 'Materiály pre Vás',
        news: 'Novinky a články',
        aboutPeroni: 'O Peroni',
        support: 'Podpora',
        faq: 'Otázky a odpovede',
        knowledgeBase: 'O portáli',
        supportTickets: 'Kontaktujte nás',
        eLearning: 'Akadémia Prvý pri zdroji',
        skELearning: 'Akadémia Prvý pri zdroji',
        pubMenu: 'Prazdroj menu',
        skPubMenu: 'Prazdroj menu',
        otherLinks: 'Ďalšie odkazy',
        legalClause: 'Právna doložka',
        backUp: 'Späť hore',
        menu: 'Menu',
        tutorial: 'Sprievodca',
        search: 'Hľadať',
        contacts: 'Kontakty',
        about: 'O nás',
        privacyPolicy: 'Ochrana súkromia',
        cookies: 'Cookies a ochrana osobných údajov',
        brands: 'Značky',
        productCode: 'kód produktu',
        createNewUser: 'Vytvoriť nového užívateľa',
        Unit: 'Balenie',
        actions: {
          edit: 'Profil',
          logout: 'Odhlásiť'
        },
        languages: {
          cs: 'Česky',
          en: 'English',
          sk: 'Slovensky'
        },
        login: 'Prihlásiť sa',
        notificationsEmpty: 'Žiadne notifikácie',
        termsOfUse: 'Podmienky používania',
        goToCart: 'Košík',
        removeItem: 'Odstrániť'
      }
    }
  }),

  computed: {
    ...mapState('main', ['pageNotFound']),
    ...mapState('basket', ['id', 'items', 'progressBarMsg']),
    ...mapState('catalog', ['wishlist']),
    ...mapGetters('main', ['getPermissions']),
    ...mapGetters('basket', ['progressBarMessage', 'progressBarSuccess', 'showProgressBarMessage']),

    menu () {
      const menuLinks = this.menuLinks
      const linksFromMenuLinks = Array.isArray(menuLinks) ? [...menuLinks] : []
      return [
        ...this.checkPermission('Catalogue', {
          label: 'Vytvořit objednávku',
          href: '/catalog/products',
          name: 'createAnOrder',
          isActive: true,
          icon: 'shopping-bag'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Košík',
          name: 'cart',
          ...(this.clientWidth >= 1000
            ? {
                href: '',
                precart: true
              }
            : { href: '/cart' }),
          icon: 'shopping-cart'
        }),
        ...this.checkPermission('Catalogue', {
          label: 'Moje oblíbené',
          name: 'favoritesMenu',
          href: '/catalog/favorites',
          icon: 'heart'
        }),
        {
          // EMPTY MENU PARENT
          isActive: true,
          children: [
            ...this.checkPermission('Home Page', {
              label: 'Hlavní stránka',
              href: '/',
              name: 'home',
              isActive: false,
              icon: 'home-alt'
            }),
            ...this.checkPermission('My Profile', {
              label: 'Můj profil',
              href: '/profile/details',
              name: 'profile',
              isActive: false,
              icon: 'user'
            }),
            ...this.checkPermission('Catalogue', {
              label: 'Katalog',
              name: 'catalog',
              isActive: false,
              icon: 'beer',
              children: [
                {
                  label: 'Produkty',
                  href: '/catalog/products',
                  name: 'products',
                  isActive: false
                },
                {
                  label: 'Moje oblíbené',
                  href: '/catalog/favorites',
                  name: 'favorites',
                  isActive: false
                },
                {
                  label: 'Uložené objednávky',
                  href: '/catalog/saved',
                  name: 'savedOrders',
                  isActive: false
                },
                ...this.checkPermission('Product Recommendation', {
                  label: 'Uložené objednávky',
                  href: '/catalog/suggested-cart',
                  name: 'suggestedCart',
                  isActive: false
                })
              ]
            }),
            ...this.checkPermission('My Orders', {
              label: 'Objednávky',
              name: 'orders',
              href: '/orders/history',
              isActive: false,
              icon: 'shopping-bag'
            }),
            ...this.checkPermission('My Documents', {
              label: 'Moje dokumenty',
              href: '/my-documents',
              name: 'myDocuments',
              isActive: false,
              icon: 'newspaper'
            }, this.user.country === 'CZE'),
            ...this.checkPermission('Reporting', {
              label: 'Přehled odběrů',
              href: '/reporting',
              name: 'reporting',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Contracts', {
              label: 'Přehled smluv',
              href: '/contracts',
              name: 'contracts',
              isActive: false,
              icon: 'file-contract'
            }),
            ...this.checkPermission('Support', {
              label: 'Podpora',
              href: '/support/faq',
              name: 'support',
              isActive: false,
              icon: 'question-circle',
              children: [
                {
                  label: 'Otázky a odpovědi',
                  href: '/support/faq',
                  name: 'faq',
                  isActive: false
                },
                {
                  label: 'O portálu',
                  href: '/support/knowledge-base',
                  name: 'knowledgeBase',
                  isActive: false
                },
                {
                  label: 'Kontaktujte nás',
                  href: '/support/tickets',
                  name: 'supportTickets',
                  isActive: false
                }
              ]
            }),
            ...this.checkPermission('Marketing Materials', {
              label: 'Materiály pro Vás',
              href: '/marketing-materials',
              name: 'marketingMaterials',
              isActive: false,
              icon: 'newspaper'
            }),
            ...this.checkPermission('Training & Learning', {
              label: 'Formazione',
              name: 'trainingLearning',
              href: '/training',
              icon: 'heart'
            }),
            ...this.checkPermission('News & Articles', {
              label: 'Novinky a články',
              href: '/news',
              name: 'news',
              isActive: false,
              icon: 'newspaper'
            }),
            // TODO: this roles probably don't exist for CZ/SK market. Suggestion to comment the code for while, just to be sure. Then remove
            // ...this.checkPermission(
            //   'Empties Management',
            //   {
            //     label: 'Portale Materiale Circolante',
            //     href: constants.saml.empties,
            //     name: 'emptiesManagement',
            //     isActive: false,
            //     icon: 'external-link-alt'
            //   },
            //   this.isOnPremiseEmptiesUser || this.isOnPremiseFranchiseEmptiesUser
            // ),
            ...this.checkPermission(
              'Academy',
              {
                label: 'Akademie První u zdroje',
                href: this.user.country === 'SVK' ? this.getLinkByName('skELearning').url : this.getLinkByName('eLearning').url,
                name: this.user.country === 'SVK' ? 'skELearning' : 'eLearning',
                isActive: false,
                icon: 'school'
              },
              this.isPOSUser || this.isWHSLUser || this.isOutletUser
            ),
            ...this.checkPermission(
              'Pub Menu',
              {
                label: 'Prazdroj menu',
                href: this.user.country === 'SVK' ? this.getLinkByName('skPubMenu').url : this.getLinkByName('pubMenu').url,
                name: this.user.country === 'SVK' ? 'skPubMenu' : 'pubMenu',
                isActive: false,
                icon: 'book-open'
              },
              this.isPOSUser || this.isOutletUser
            ),
            ...linksFromMenuLinks
          ]
        }
      ]
    },
    footerLinksSocial () {
      const aboutUsLinks = this.portalHardCodeLink
        .filter((data) => data.type.toLowerCase() === 'social media')
        .map((data) => ({
          label: data.name,
          link: data.url,
          target: '_blank'
        }))
      return aboutUsLinks
    },
    portalHardCodeLink (): IPortalHardCodeLink[] {
      return this.$store.state.links.portalHardCodeLink?.results ?? []
    },
    total () {
      return this.$store.state.links.portalHardCodeLink.total
    },

    limit () {
      return this.$store.state.links.portalHardCodeLink.limit
    },
    footerLinks () {
      let aboutUsLinks

      if (this.user.country === 'SVK') {
        aboutUsLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'about us sk' || data.type.toLowerCase() === 'about us')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      } else {
        aboutUsLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'about us cz' || data.type.toLowerCase() === 'about us')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      }

      return [
        {
          title: 'about'
        },
        ...aboutUsLinks
      ]
    },
    footerLinksBrands () {
      let brandLinks

      if (this.user.country === 'SVK') {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands sk' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      } else {
        brandLinks = this.portalHardCodeLink
          .filter((data) => data.type.toLowerCase() === 'mobile brands cz' || data.type.toLowerCase() === 'mobile brands')
          .map((data) => ({
            label: data.label,
            link: data.url,
            target: '_blank',
            order: data.order
          }))
          .sort((a, b) => a.order - b.order)
      }
      return [
        {
          title: 'brands'
        },
        ...brandLinks
      ]
    },
    footerLinksMobile () {
      return [
        ...this.footerLinksBrands, ...this.footerLinks
      ]
    },

    linksStore () {
      return this.$store.state.links
    },
    queries () {
      return this.$store.state.catalog.queries
    },
    showSearchBar () {
      return this.checkPermission('Catalogue', {}).length !== 0
    },

    languages () {
      if (this.user.country === 'CZE' || window.location.hostname.includes('.cz')) {
        return ['cs', 'en']
      }

      if (this.user.country === 'SVK' || window.location.hostname.includes('.sk')) {
        return ['sk', 'en']
      }

      return ['en', this.activeLanguage].filter((x, index, self) => x != null && self.indexOf(x) === index)
    },

    menuLinks () {
      const links = this.linksStore?.links?.map((link, index) => {
        return {
          label: link?.title,
          href: link?.url,
          name: 'custom-link' + (index + 1),
          isActive: false,
          icon: 'external-link-alt',
          isOther: true,
          children: []
        }
      })
      return links
    },
    computedDictionary () {
      const links = this.linksStore?.links?.reduce((acc, value, index) => {
        acc['custom-link' + (index + 1)] = value?.title
        return acc
      }, {} as Record<string, string>)

      const activeLanguage = this.activeLanguage as keyof typeof this.dictionary
      return { ...this.dictionary, [activeLanguage]: { ...this.dictionary[activeLanguage], ...links } }
    },

    user () {
      return this.$store.state.auth.user
    },

    isLoggedIn () {
      return this.user.isLoggedIn // && !!this.user.userValidation
    },
    notAuthorized () {
      return this.$route.meta?.requireAuthentication && !this.isLoggedIn
    },
    showFooter (): boolean {
      return !this.$route.meta?.hideFooter
    },
    cartItems () {
      return this.items?.length
    },
    favoritesItems () {
      return this.wishlist?.items_v2?.shipto_item_count
    },
    isPOSUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails.some((rp) => rp.customerType === 'POS'))
    },
    isWHSLUser () {
      return this.user?.permissionSchema?.some((company) => company.rpDetails?.some((rp) => rp.customerType === 'WHSL'))
    },
    isOutletUser () {
      return this.user?.permissionSchema?.some((company) =>
        company.rpDetails?.some((rp) => rp.customerType === 'Outlets')
      )
    },
    // TODO: this roles probably don't exist for CZ/SK market. Suggestion to comment the code for while, just to be sure. Then remove
    // isOnPremiseEmptiesUser () {
    //   return this.user?.permissionSchema?.some((company) =>
    //     company.rpDetails?.some((rp) => rp.rolename === 'On Premise Empties Management')
    //   )
    // },
    // isOnPremiseFranchiseEmptiesUser () {
    //   return this.user?.permissionSchema?.some((company) =>
    //     company.rpDetails?.some((rp) => rp.rolename === 'On Premise Franchisee Empties Management')
    //   )
    // },
    allPermission () {
      return this.user.allAvailablePermission
    },
    helpPagesStore () {
      return this.$store.state.helpPages
    },
    customerType (): RolePermissionDetails | undefined {
      if (this.user && this.user?.permissionSchema?.length > 0) {
        return this.user?.permissionSchema[0]?.rpDetails?.find((item: RolePermissionDetails) => item.customerType)
      }
      return undefined
    },
    isPOSAcademyUser () {
      return this.user.permissionSchema?.every(permission => permission.rpDetails.every(
        roleItem =>
          roleItem.rolename === 'POS Academy' ||
            roleItem.roleid === 'f5ab4859-9197-47e5-bc9e-097283c35d94'
      ))
    }
  },

  watch: {
    isLoggedIn: {
      handler (value) {
        if (value === true) {
          this.fetchLinks()
          this.fetchPortalHardCodeLink()
        }
      }
    },
    activeLanguage: {
      handler (value) {
        this.setMagentoStore(value)
        if (this.isLoggedIn) {
          this.fetchLinks()
          this.fetchPortalHardCodeLink()
        }
      },
      immediate: true
    },

    $route () {
      this.initFromRoute()
    },

    progressBarMsg (value, oldValue) {
      if (oldValue !== value) {
        this.$store.commit('basket/SET_PROGRESS_BAR', value)
        return value
      }
    }
  },

  created () {
    this.initFromRoute()
  },

  async mounted () {
    if (window.location.hostname.includes('.cz') && this.activeLanguage !== 'en') {
      this.changeLanguage('cs')
    }

    if (window.location.hostname.includes('.sk') && this.activeLanguage !== 'en') {
      this.changeLanguage('sk')
    }

    this.checkGigyaReady()
  },

  methods: {
    checkGigyaReady () {
      if (window.gigya) {
        window.gigya.hasSession().then(this.checkSession)
        window.gigya.socialize.addEventHandlers({
          onLogin: async (res: IAuthState) => {
            await this.updateUserInfo(res)
            localStorage.setItem('shipToId', '')
            await this.$store.dispatch('basket/CREATE_EMPTY_CART', { root: true })
          }
        })
      } else {
        setTimeout(() => {
          this.checkGigyaReady()
        }, 100)
      }
    },

    initFromRoute () {
      this.isPortalChrome = !this.$route.meta?.noPortalChrome
      const urlParams = new URLSearchParams(window.location.search)
      this.gigICode = urlParams.get('gig_i_code') || ''
      this.gigIvToken = urlParams.get('gig_i_vToken') || ''
    },
    checkPermission (permission: string, value: Record<string, any>, additionalPremmision = true) {
      if (this.customerType) localStorage.setItem('customertype', this.customerType?.customerType)

      return this.allPermission?.includes(permission) &&
        additionalPremmision &&
        getValueByLabel(this.getPermissions(), permission)
        ? [value]
        : []
    },
    async dispatchHelpPages () {
      await this.$store.dispatch(HelpPagesActionType.FETCH_ALL_HELP_PAGES)
    },
    openTutorialModal () {
      if (
        !(this.helpPagesStore !== undefined && this.helpPagesStore.helpPages && this.helpPagesStore.helpPages.length)
      ) {
        this.dispatchHelpPages()
      }
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).open()
    },
    closeTutorialModal () {
      (this.$refs.tutorialModal as InstanceType<typeof ModalComponent>).close()
    },
    menuNavigate (item: CustomEvent) {
      if (
        item.detail?.name === 'eLearning' ||
        item.detail?.name === 'skELearning' ||
        item.detail?.name === 'skPubMenu' ||
        item.detail?.name === 'pubMenu'
      ) {
        window.gigya.fidm.saml.initSSO({
          spName: item.detail?.name,
          redirectURL: item.detail.href
        })
        return
      } else if (/^https?:\/\//i.test(item.detail.href) || /^https?:\/\//i.test(item.detail) || /^\/files\/\S*\w+\.\w+/i.test(item.detail)) {
        const link = item.detail.href ? item.detail.href : item.detail
        window.open(link, '_blank')
        return
      }

      // Default navigation
      this.$navigate(item)
    },

    changeLanguage (langCode: LanguagesType) {
      this.activeLanguage = langCode
      setI18nLanguage(langCode)
    },

    fetchLinks () {
      this.$store.dispatch(LinksActionType.FETCH_ALL_LINKS)
    },
    fetchPortalHardCodeLink () {
      this.$store.dispatch(LinksActionType.FETCH_PORTAL_HARD_CODE_LINK)
    },
    getLinkByName (name: string) {
      const linkObj = this.portalHardCodeLink.find((link) => link.name === name)
      return linkObj ? { url: linkObj.url, label: linkObj.label } : { url: '', label: '' }
    },

    /**
     * Method to update logged in user info into vuex store
     * @param res
     */
    async updateUserInfo (res: IAuthState) {
      sessionStorage.setItem('uid', res.UID) // This is for Google Analytics
      this.$store.commit('SET_AUTH_USER', res)
      await this.$store.dispatch(AuthActionTypes.GET_USER_INFO, res.UID).then(() => {
        if (!this.user.userValidation && !this.$route.meta?.isUserVerification) {
          this.userLogout()
          return
        }

        if (this.user.country === 'CZE' && this.activeLanguage !== 'en') {
          this.changeLanguage('cs')
        }

        if (this.user.country === 'SVK' && this.activeLanguage !== 'en') {
          this.changeLanguage('sk')
        }

        this.setMagentoStore(this.activeLanguage)

        this.fetchLinks()
      })

      if (!!this.user.userValidation && !this.$route.meta?.isUserVerification) {
        await this.checkPOSAcademyUser()
      }

      if (this.user.firstLogin === true) {
        this.openTutorialModal()
      }

      if (localStorage.getItem('customertype') && localStorage.getItem('shippingMethod')) {
        this.$store.dispatch('main/FETCH_ORDERABLE_UNITS_CONFIG',
          {
            customerType: localStorage.getItem('customertype'),
            orderType: localStorage.getItem('shippingMethod')
          }, { root: true })
      }
    },
    checkPOSAcademyUser () {
      if (this.isPOSAcademyUser) {
        window.gigya.fidm.saml.initSSO({
          spName: this.user.country === 'SVK' ? 'skELearning' : 'eLearning',
          redirectURL: this.user.country === 'SVK' ? this.getLinkByName('skELearning').url : this.getLinkByName('eLearning').url
        })
      }
    },
    /**
     * Method to initiate logout action
     * on click action of logout button
     */
    userLogout () {
      sessionStorage.removeItem('uid')
      sessionStorage.removeItem('imp_usr')
      const params = {
        UID: this.user.UID,
        callback: this.handleLogout
      }
      window.gigya.accounts.logout(params)
    },
    /**
     * Method to perform action after user logs out
     * Navigate user to home page
     */
    handleLogout () {
      this.$store.dispatch('logout')
      this.$navigate('/login')
    },
    /**
     * Method to check user session if it exist
     * if it does not exist then redirect user to login page
     * @param sessionExist
     */
    async checkSession (sessionExist: boolean) {
      if (sessionExist) {
        window.gigya.socialize.getUserInfo({ callback: this.updateUserInfo })
      } else {
        if (!this.$route.meta?.isUserVerification && this.$route.meta?.requireAuthentication) {
          this.$navigate('/login')
        }
      }
      this.isLoaded = true
    },
    /**
     * Callback method on click of Profile link under top right user name
     * @param event
     */
    userEdit () {
      this.$navigate('/profile/details')
    },

    setMagentoStore (language: string) {
      if (this.user.country === 'SVK' || (this.user.country == null && window.location.hostname.includes('.sk'))) {
        localStorage.setItem('store', `LivePortal_SK_${language}`)
      }
      if (this.user.country === 'CZE' || (this.user.country == null && window.location.hostname.includes('.cz'))) {
        localStorage.setItem('store', `LivePortal_CZ_${language}`)
      }

      if (!(this.user.country == null || this.user.country === '') && !this.$store.state.auth.isLanguageLoaded) {
        this.$store.commit(AuthMutationTypes.SET_LANGUAGE_LOADED, true)
      }
    },

    async search (event: CustomEvent) {
      this.searchValue = event.detail
      await this.fetchSearching(this.searchValue)
      const catalogQuery = {
        ...this.queries,
        search: this.searchValue
      }
      this.$store.commit('catalog/SET_QUERIES', catalogQuery, { root: true })
      this.$store.commit(ArticlesMutationType.SET_SEARCH, this.searchValue, { root: true })
      this.$store.commit(FilesMutationType.SET_SEARCH, this.searchValue, { root: true })
    },

    async fetchSearching (dto: string): Promise<IFetchSearchResponse[] | undefined> {
      try {
        if (dto != null && dto !== '') {
          const result = [] as IFetchSearchResponse[]
          if (this.checkPermission('Catalogue', {}).length > 0) {
            result.push({ name: this.$t('Catalogue'), path: '/catalog/products' })
          }
          result.push({ name: this.$t('News and Articles'), path: '/news' })
          result.push({ name: this.$t('Marketing materials'), path: '/marketing-materials' })
          this.searchValues = result
        } else {
          this.searchValues = new Array(0) as IFetchSearchResponse[]
        }
        return this.searchValues
      } catch (error) {
        console.error(error)
      }
    },

    onResizePortal ({ height }: { height: number }) {
      this.clientWidth = document.querySelector('body')?.clientWidth || height
    },

    async removeProductFromCart (productId: string) {
      if (this.$store.state.basket?.id && productId) {
        await this.$store.dispatch('basket/REMOVE_ITEM', { cart_id: this.$store.state.basket.id, cart_item_id: productId }, { root: true })
        await this.$store.dispatch('basket/FETCH_MIN_CART_QUERY_FOR_CATALOG', { cart_id: this.$store.state.basket.id }, { root: true })
      }
    }
  }
})
