import { instance } from '@/store/api-instance'
import { IGetAvailableDateRequest } from '../interfaces/getAvailableDates/IGetAvailableDateRequest'
import { AxiosResponse } from 'axios'

async function getAvailableDateAPI (queryParams: IGetAvailableDateRequest) {
  const response: AxiosResponse = await instance.get('orders/GetFirstAvailableDate', { params: queryParams })
  return response.data
}
export {
  getAvailableDateAPI
}
