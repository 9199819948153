import gql from 'graphql-tag'

export const fetchRemoveCardItemMutation = gql`
  mutation ($cart_id: String!, $cart_item_id: Int!) {
    removeItemFromCart(input: { cart_id: $cart_id, cart_item_id: $cart_item_id }) {
      cart {
        items {
          id
          product {
            name
            sku
            description {
              html
            }
            url_key
            number_of_items_on_pallet
            price_range {
              minimum_price {
                regular_price {
                  value
                  currency
                }
              }
            }
            swatch_image
            image {
              url
            }
          }
          prices {
            price_details
            total_item_discount {
              value
            }
            price {
              value
            }
            favourites
            row_total {
              value
            }
            discounts {
              label
              amount {
                value
              }
            }
          }
          quantity
          number_of_layer
          number_of_layer_total
          number_of_pallet
          quantity_total
          pack_format
        }
        prices {
          grand_total {
            value
            currency
          }
        }
      }
    }
  }
`
