import gql from 'graphql-tag'

export const createSuggestedCartQuery = gql`
query ($shipTo: String, $cartId: String) {
  suggestedCart(
    shipTo: $shipTo
    cartId: $cartId
  ){
    cartId
  }
}
`
