<template>
<div>
  <img src="../../assets/loader.gif" alt="Loader spinner">
</div>
</template>

<script>
export default {
  name: 'LoaderSpinner'
}
</script>

<style scoped>

img {
  width: 100px;
  height: 100px;
}
</style>
