export enum OrderableUnit {
    PACKAGING = 'Packaging',
    PALLETS = 'Pallets',
    LAYERS = 'Layers'
}

export interface IOrderableUnits {
    units: OrderableUnit[],
    loaded: boolean
}

export interface ShowOrderableUnits {
    loaded: boolean,
    showPallets: boolean,
    showLayer: boolean,
    showPackaging: boolean
}
