import gql from 'graphql-tag'

export const fetchRecommendedListQuery = gql`
query ($shipTo: String, $selectedItem: String, $cartItems: [CartItemsInput]) {
  recommendItems(
    shipTo: $shipTo
    selectedItem: $selectedItem
    cartItems: $cartItems
  ) {
    shipTo
    recItems {
      productId
      Priority
    }
  }
}
`
