import { resolveComponent as _resolveComponent, createVNode as _createVNode, createElementVNode as _createElementVNode, toDisplayString as _toDisplayString, createTextVNode as _createTextVNode, withCtx as _withCtx, openBlock as _openBlock, createBlock as _createBlock, createCommentVNode as _createCommentVNode, createElementBlock as _createElementBlock, pushScopeId as _pushScopeId, popScopeId as _popScopeId } from "vue"

const _withScopeId = n => (_pushScopeId("data-v-2902fd25"),n=n(),_popScopeId(),n)
const _hoisted_1 = { class: "text-center" }
const _hoisted_2 = { class: "d-block icon my-3" }
const _hoisted_3 = {
  key: 2,
  class: "d-flex flex-column flex-md-row align-items-center justify-content-between"
}
const _hoisted_4 = { key: 3 }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  const _component_asahi_icon = _resolveComponent("asahi-icon")!
  const _component_asahi_typography = _resolveComponent("asahi-typography")!
  const _component_asahi_button = _resolveComponent("asahi-button")!

  return (_openBlock(), _createElementBlock("div", _hoisted_1, [
    _createElementVNode("span", _hoisted_2, [
      _createVNode(_component_asahi_icon, {
        icon: _ctx.icon || 'question-circle',
        size: "32"
      }, null, 8, ["icon"])
    ]),
    (_ctx.title)
      ? (_openBlock(), _createBlock(_component_asahi_typography, {
          key: 0,
          type: "headline-1",
          color: "primary-gold",
          class: "d-block mt-3 mb-5"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.title), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.message)
      ? (_openBlock(), _createBlock(_component_asahi_typography, {
          key: 1,
          type: "subtitle-1",
          class: "d-block mb-3"
        }, {
          default: _withCtx(() => [
            _createTextVNode(_toDisplayString(_ctx.message), 1)
          ]),
          _: 1
        }))
      : _createCommentVNode("", true),
    (_ctx.cancel)
      ? (_openBlock(), _createElementBlock("div", _hoisted_3, [
          _createElementVNode("div", null, [
            _createVNode(_component_asahi_button, {
              color: "secondary-favorite",
              onClick: _cache[0] || (_cache[0] = () => _ctx.onCancel()),
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.cancel), 1)
              ]),
              _: 1
            })
          ]),
          _createElementVNode("div", null, [
            _createVNode(_component_asahi_button, {
              color: "secondary-red",
              onClick: _cache[1] || (_cache[1] = () => _ctx.onConfirm()),
              class: "mt-4"
            }, {
              default: _withCtx(() => [
                _createTextVNode(_toDisplayString(_ctx.confirm), 1)
              ]),
              _: 1
            })
          ])
        ]))
      : (_openBlock(), _createElementBlock("div", _hoisted_4, [
          _createVNode(_component_asahi_button, {
            color: "secondary-red",
            onClick: _cache[2] || (_cache[2] = () => _ctx.onConfirm()),
            class: "mt-4"
          }, {
            default: _withCtx(() => [
              _createTextVNode(_toDisplayString(_ctx.confirm), 1)
            ]),
            _: 1
          })
        ]))
  ]))
}