import { createI18n } from 'vue-i18n'
import { constants } from '@/constants'

function loadMessages () {
  const context = require.context('./locales', true, /\.json$/i)

  const messages = context
    .keys()
    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
    .map((key) => ({ key, locale: key.match(/[a-z0-9-_]+/i)![0] }))
    .reduce(
      (messages, { key, locale }) => ({
        ...messages,
        [locale]: context(key)
      }),
      {}
    )

  return { context, messages }
}

const { messages } = loadMessages()

let defaultLanguage = 'cs'
if (constants.application.theme === 'peroni') {
  defaultLanguage = 'it'
}

const i18n = createI18n({
  globalInjection: true,
  legacy: false,
  locale: localStorage.getItem('lang') || defaultLanguage,
  fallbackLocale: 'en',
  messages,
  pluralRules: {
    cs: function (choice: number, choicesLength: number): number {
      if (choice === 0) {
        return 0
      }

      const teen = choice > 10 && choice < 20
      const endsWithOne = choice % 10 === 1

      if (choicesLength < 4) {
        return (!teen && endsWithOne) ? 1 : 2
      }
      if (!teen && endsWithOne) {
        return 1
      }
      if (!teen && choice % 10 >= 2 && choice % 10 <= 4) {
        return 2
      }

      return (choicesLength < 4) ? 2 : 3
    }
  }
})

export function setI18nLanguage (locale: string) {
  i18n.global.locale.value = locale
  document.querySelector('html')?.setAttribute('lang', locale)
  localStorage.setItem('lang', locale)
}

export default i18n
