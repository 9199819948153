import gql from 'graphql-tag'

export const fetchCartQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    note_for_driver
    customer_order_number
    delivery_date
    delivery_method
    outlet_details {
      payer_name
      outlet_name
      street
      city
      country
      province
      postalcode
      sap_id
    }
    payer_details {
      payername
      street
      city
      country
      zipcode
      sap_id
      identification_no
      taxvat
    }
    progress_bar_data {
      isFullTruck
      iMinimumOrderPallet
      iMaximumOrderPallet
      iMinimumOrderWeight
      iMaximumOrderWeight
      iMaximumOrderCash
      sOutletDeliveryMethod
      iTotalCartWeight
      iTotalCartPalletQty
    }
    cart_prices {
      grand_total_empties_deposit
    }
    id
    email
    total_quantity
    ship_to_id
    grand_total_your_price_details
    quantity_totals
    items {
      id
      uid
      prices {
        price_details
        multi_deposit_details 
        total_item_discount {
          value
        }
        price {
          value
        }
        favourites
        row_total {
          value
        }
        discounts {
          label
          amount {
            value
          }
        }
      }
      product {
        id
        name
        catalog_product_name
        sku
        url_key
        material_type
        number_of_items_in_box
        number_of_items_on_pallet
        swatch_image
        number_of_products_on_layer
        number_of_layers_per_pallet
        isavailable_truck_package_ordering
        image {
          url
        }
        ... on BundleProduct {
          bundle_price_details
        }
        __typename
      }
      ... on BundleCartItem {
          bundle_options {
            uid
            label
            type
            values {
              id
              label
              price
              quantity
            }
          }
        }
      quantity
      quantity_total
      number_of_packages
      number_of_layer
      number_of_layer_total
      number_of_pallet
      pack_format
    }
    prices {
      discounts {
        amount {
          value
        }
        label
      }
      grand_total {
        value
      }
    }
    empties{
      packaging_name
      packaging_volume
      packaging_issued
      number_of_units
      unit_of_measure
      total
    }
    cart_blga {
      packaging_name
      packaging_volume
      number_of_units
      unit_of_measure
      total
    }
  }
}
`)
