export enum UsersMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_USERS = 'SET_USERS',
  SET_SELECTED_USER = 'SET_SELECTED_USER',
  SET_IS_USER_SAVED = 'SET_IS_USER_SAVED',
  SET_LOGGED_IN_USER = 'SET_LOGGED_IN_USER',
  SET_IS_PASSWORD_CHANGED = 'SET_IS_PASSWORD_CHANGED',
  SET_PAYER_DETAILS = 'SET_PAYER_DETAILS',
  SET_USER_STATUS = 'SET_USER_STATUS',
  SET_PAYER_SOLD_TO = 'SET_PAYER_SOLD_TO',
  SET_VERIFICATIION_MAIL_SENT = 'SET_VERIFICATIION_MAIL_SENT'
}
