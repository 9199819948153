import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { IReportingActions } from '@/store/modules/reporting/interfaces/IReportingActions'
import { IReportingState } from '@/store/modules/reporting/interfaces/IReportingState'
import { IReportingMutations } from '@/store/modules/reporting/interfaces/IReportingMutations'
import { IReportingRequest } from '@/store/modules/reporting/interfaces/api/IReportingRequest'
import { ITokenData } from '@/store/modules/reporting/interfaces/api/IReportingResponse'
import { ReportingMutationType } from '@/store/modules/reporting/interfaces/mutation-type'
import { ReportingActionType } from '@/store/modules/reporting/interfaces/action-type'
import { reportingAPI } from '@/store/modules/reporting/api/reporting-api'

const state = (): IReportingState => ({
  tokenDetails: {} as ITokenData,
  contractEvaluationReportTokenDetails: {} as ITokenData,
  loading: false
})

const mutations: MutationTree<IReportingState> & IReportingMutations = {
  [ReportingMutationType.SET_LOADING] (state: IReportingState, payload: boolean) {
    state.loading = payload
  },
  async [ReportingMutationType.SET_POWER_BI_ACCESS_DETAILS] (state: IReportingState, payload: ITokenData) {
    state.tokenDetails = payload
  },
  async [ReportingMutationType.SET_POWER_BI_CONTRACTS_ACCESS_DETAILS] (state: IReportingState, payload: ITokenData) {
    state.contractEvaluationReportTokenDetails = payload
  }
}

const actions: ActionTree<IReportingState, IRootState> & IReportingActions = {
  async [ReportingActionType.FETCH_POWER_BI_ACCESS_DETAILS] ({ commit }, dto: IReportingRequest):Promise<void> {
    commit(ReportingMutationType.SET_LOADING, true)
    const response = await reportingAPI.getPowerBIDetails(dto)
    commit(ReportingMutationType.SET_POWER_BI_ACCESS_DETAILS, response.data)
    commit(ReportingMutationType.SET_LOADING, false)
  },
  async [ReportingActionType.FETCH_POWER_BI_CONTRACTS_ACCESS_DETAILS] ({ commit }, dto: IReportingRequest):Promise<void> {
    commit(ReportingMutationType.SET_LOADING, true)
    const response = await reportingAPI.getContractsPowerBIDetails(dto)
    commit(ReportingMutationType.SET_POWER_BI_CONTRACTS_ACCESS_DETAILS, response.data)
    commit(ReportingMutationType.SET_LOADING, false)
  }
}

const ReportingModule = {
  state,
  mutations,
  actions
}

export default ReportingModule
