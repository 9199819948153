
import { defineComponent } from 'vue'

const validateUser = (user: any) => {
  const errorMessages = []

  if (user.isLoggedIn) {
    if (!(user.country != null && user.country !== '')) {
      errorMessages.push('Country is not fill')
    }

    if (
      !user.permissionSchema?.some((permission: any) =>
        permission.rpDetails?.some((rp: any) => rp.customerType != null && rp.customerType !== '')
      )
    ) {
      errorMessages.push('Customer type is not fill')
    }
  }
  if (errorMessages.length) {
    console.error(errorMessages.join(', '))
  }
  return ''// errorMessages.join(', ')
}

export default defineComponent({
  name: 'UserToolbar',

  data: () => ({}),

  computed: {
    errors () {
      return validateUser(this.$store.state.auth.user)
    },

    isFullLoaded () {
      return this.$store.state.auth.isFullLoaded && this.$store.state.auth.isLanguageLoaded
    }
  }
})
