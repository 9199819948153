import { IPermissionsConfig } from '@/store/modules/main/intefaces/IConfig'

export function getValueByLabel (permissions : IPermissionsConfig[], key : string): boolean {
  if (permissions.length) {
    if (key === 'Home Page' || key === 'Pub Menu' || key === 'Product Recommendation' || key === 'My Documents') {
      return true
    }
    for (let i = 0; i < permissions.length; i++) {
      if (permissions[i].label === key && permissions[i].value === '1') {
        return true
      }
    }
  }
  return false
}
export function checkPermission (userPermission: string[], globalPermission: IPermissionsConfig[], permission: string, value: Record<string, any>, additionalPremmision = true) {
  return userPermission?.includes(permission) &&
    additionalPremmision &&
    getValueByLabel(globalPermission, permission)
    ? [value]
    : []
}

export function hasPermission (allAvailablePermission: string[], globalPermission: IPermissionsConfig[], permission: string) {
  return !!(!!allAvailablePermission?.includes(permission) &&
  getValueByLabel(globalPermission, permission))
}
