import gql from 'graphql-tag'

export const fetchHolidaysConfigQuery = gql(`
  query {
    getPortalConfig {
      holidays {
        january
        february
        march
        april
        may
        june
        july
        august
        september
        october
        november
        december
      }
    }
  }
`)
