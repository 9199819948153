import gql from 'graphql-tag'

export const cancelSavedOrderMutation = gql(`
mutation ($sap_id: String!) {
    cancelOrder(
    sap_id: $sap_id
  ) {
    message
    status
  }
}`)
