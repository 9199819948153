import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { IDocumentResponse, IDocumentTypeResponse } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'

export const documentsAPI = {
  async getDocuments (dto: IDocumentRequest): Promise<IDocumentResponse> {
    const response: AxiosResponse<IDocumentResponse> = await instance.get('documents/GetAllDocuments', { params: dto })
    return response.data
  },
  async getDocumentTypes (): Promise<IDocumentTypeResponse> {
    const response: AxiosResponse<IDocumentTypeResponse> = await instance.get('documents/GetAdrDeliveryDocumentsType', {})
    return response.data
  },
  async getDocumentLink (documentId: string, documentType: string): Promise<string> {
    const response: AxiosResponse<string> = await instance.get('documents/GetDocumentExternalLink', { params: { documentId, documentType } })
    return response.data
  }
}
