import { ActionTree, MutationTree } from 'vuex'
import { IContractsState } from '@/store/modules/contracts/interfaces/IContractsState'
import { IContractsMutations } from '@/store/modules/contracts/interfaces/IContractsMutations'
import { ContractsMutationType } from '@/store/modules/contracts/interfaces/mutation-type'
import { IContract } from '@/store/modules/contracts/interfaces/api/IContractsResponse'
import { IRootState } from '@/store'
import { IContractsActions } from '@/store/modules/contracts/interfaces/IContractsActions'
import { ContractsActionType } from '@/store/modules/contracts/interfaces/action-type'
import { contractsAPI } from '@/store/modules/contracts/api/getContracts'

const state = (): IContractsState => ({
  contracts: [],
  loading: false
})

const mutations: MutationTree<IContractsState> & IContractsMutations = {
  [ContractsMutationType.SET_LOADING] (state: IContractsState, payload: boolean) {
    state.loading = payload
  },
  async [ContractsMutationType.SET_CONTRACTS] (state: IContractsState, payload: IContract[]) {
    state.contracts = payload
  }
}

const actions: ActionTree<IContractsState, IRootState> & IContractsActions = {
  async [ContractsActionType.FETCH_CONTRACTS] ({ commit }) {
    commit(ContractsMutationType.SET_LOADING, true)
    const data = await contractsAPI.fetchContracts()
    commit(ContractsMutationType.SET_CONTRACTS, data.contracts)
    commit(ContractsMutationType.SET_LOADING, false)
  }
}

const ContractsModule = {
  state,
  mutations,
  actions
}

export default ContractsModule
