import gql from 'graphql-tag'

export const fetchUpdateCartItemsMutation = gql(`
mutation ($cart_id: String!, $cart_items: [CartItemUpdateInput]!) {
  updateCartItems(
    input: {
      cart_id: $cart_id
      cart_items: $cart_items
    }
  ) {
      cart {
        grand_total_your_price_details
        items {
          id
          prices {
            price_details
            multi_deposit_details 
          }
          quantity
          quantity_total
          number_of_packages
          number_of_layer
          number_of_layer_total
          number_of_pallet
        }
      }
    }
  }
`)
