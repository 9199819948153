
import { defineComponent } from 'vue'

export default defineComponent({
  name: 'HelpActionButton',
  computed: {
    isIncludeLogin () {
      return !this.$route.fullPath.includes('login')
    },

    allPermission () {
      return this.$store.state.auth.user.allAvailablePermission
    }
  }
})
