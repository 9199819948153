import gql from 'graphql-tag'

export const fetchCustomerLastSettingsQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  ) {
    delivery_date
    delivery_method
    ship_to_id
  }
}
`)
