
export const savedOrderList = `
    {
        "data": {
            "getSaveOrderForLater": [
                {
                    "id": "3261",
                    "date": "2022-11-22 06:45:13",
                    "total": "0.0000",
                    "deliver_to": "",
                    "bill_to": "Magento Pkwy Main Street,Austin,Texas,US,78758,",
                    "saved_order_later": true,
                    "is_active": false,
                    "cart_id": "Lhs4O8a1EV4dLd6xDnqIh2DjCQmGpbeq"
                },
                {
                    "id": "3262",
                    "date": "2022-11-23 10:04:25",
                    "total": "0.0000",
                    "deliver_to": "",
                    "bill_to": "Magento Pkwy Main Street,Austin,Texas,US,78758,Magento Pkwy Main Street,Austin,Texas,US,78758,",
                    "saved_order_later": true,
                    "is_active": false,
                    "cart_id": "DT1QTFNUvr1OrE9JzirDKb8HZrGs6EbH"
                }
            ]
        }
    }
`

export const shipmentPlaces = `[
    {
        "value": 1,
        "label": "Jeremiášova 1249/7, Praha 5"
    },
    {
        "value": 2,
        "label": "BAŽANTÍ 586/17, OSTRAVA - HRUŠOV"
    },
    {
        "value": 3,
        "label": "JAROMÍRA VEJVODY 1438, PRAHA 5 - ZBRASLAV"
    },
    {
        "value": 4,
        "label": "ŠIROKÁ 590/3, HAVÍŘOV - MĚSTO"
    },
    {
        "value": 5,
        "label": "ŠIROKÁ 590/3, HAVÍŘOV - MĚSTO"
    }
]`

export const orderTransportTypes = `[
    {
        "value": 0,
        "label": "Order type"
    },
    {
        "value": 1,
        "label": "Truck"
    },
    {
        "value": 2,
        "label": "Small"
    },
    {
        "value": 3,
        "label": "Collection of empty packaging - small order"
    },
    {
        "value": 4,
        "label": "Collection of empty packaging - truck"
    }
]`

export const orderStatus = `{
    "en":[
    {
        "value": "",
        "label": "Status"
    },
    {
        "value": "Completely Processed",
        "label": "Completely Processed"
    },
    {
        "value": "Fully Rejected",
        "label": "Fully Rejected"
    },
    {
        "value": "Not Relevant",
        "label": "Not Relevant"
    },
    {
        "value": "Not Yet Processed",
        "label": "Not Yet Processed"
    },
    {
        "value": "Partially Processed",
        "label": "Partially Processed"
    }
],
"cs":[
    {
        "value": "",
        "label": "Stav"
    },
    {
        "value": "Zcela zpracováno",
        "label": "Zcela zpracováno"
    },
    {
        "value": "Plně zamítnuto",
        "label": "Plně zamítnuto"
    },
    {
        "value": "Irelevantní",
        "label": "Irelevantní"
    },
    {
        "value": "Dosud nezpracováno",
        "label": "Dosud nezpracováno"
    },
    {
        "value": "Částečně zpracováno",
        "label": "Částečně zpracováno"
    }

],
"it":[
    {
        "value": "",
        "label": "Stato"
    },
    {
        "value": "Completely Processed",
        "label": "Completamente elaborato"
    },
    {
        "value": "Fully Rejected",
        "label": "Completamente rifiutato"
    },
    {
        "value": "Not Relevant",
        "label": "Non rilevante"
    },
    {
        "value": "Not Yet Processed",
        "label": "Non ancora elaborato"
    },
    {
        "value": "Partially Processed",
        "label": "Parzialmente elaborato"
    }

],
"sk":[
    {
        "value": "",
        "label": "Stav"
    },
    {
        "value": "Completely Processed",
        "label": "Kompletne spracované"
    },
    {
        "value": "Fully Rejected",
        "label": "Úplne zamietnuté"
    },
    {
        "value": "Not Relevant",
        "label": "Nie je relevantné"
    },
    {
        "value": "Not Yet Processed",
        "label": "Ešte nespracované"
    },
    {
        "value": "Partially Processed",
        "label": "Čiastočne spracované"
    }

]}`

export const orderStatusIT = `{
    "en":[
    {
        "value": "",
        "label": "Delivery Status"
    },
    {
        "value": "CANCELLED",
        "label": "CANCELLED"
    },
    {
        "value": "ORDER UNDER INVESTIGATION",
        "label": "ORDER UNDER INVESTIGATION"
    },
    {
        "value": "AWAITING SHIPMENT",
        "label": "AWAITING SHIPMENT"
    },
    {
        "value": "PARTIAL ORDER PREPARATION",
        "label": "PARTIAL ORDER PREPARATION"
    },
    {
        "value": "AWAITING FULLFILLMENT",
        "label": "AWAITING FULLFILLMENT"
    },
    {
        "value": "AWAITING DELIVERY",
        "label": "AWAITING DELIVERY"
    },
    {
        "value": "DELIVERED",
        "label": "DELIVERED"
    },
    {
        "value": "PARTIALLY DELIVERED",
        "label": "PARTIALLY DELIVERED"
    },
    {
        "value": "DELIVERY NOT AVAILABLE",
        "label": "DELIVERY NOT AVAILABLE"
    },
    {
        "value": "IN STOCK",
        "label": "IN STOCK"
    },
    {
        "value": "NOT DELIVERED",
        "label": "NOT DELIVERED"
    }
],
"it":[
    {
        "value": "",
        "label": "Stato consegna"
    },
    {
        "value": "CANCELLED",
        "label": "REVOCATO"
    },
    {
        "value": "ORDER UNDER INVESTIGATION",
        "label": "ORDINE IN CORSO DI VERIFICA"
    },
    {
        "value": "AWAITING SHIPMENT",
        "label": "DA EVADERE"
    },
    {
        "value": "PARTIAL ORDER PREPARATION",
        "label": "ORDINE PARZIALE IN PREPARAZIONE"
    },
    {
        "value": "AWAITING FULLFILLMENT",
        "label": "CONSEGNA IN PREPARAZIONE"
    },
    {
        "value": "AWAITING DELIVERY",
        "label": "IN CONSEGNA"
    },
    {
        "value": "DELIVERED",
        "label": "CONSEGNA EFFETTUATA"
    },
    {
        "value": "PARTIALLY DELIVERED",
        "label": "CONSEGNA PARZIALE"
    },
    {
        "value": "DELIVERY NOT AVAILABLE",
        "label": "ESITO CONSEGNA NON DISPONIBILE"
    },
    {
        "value": "IN STOCK",
        "label": "CONSEGNA IN GIACENZA"
    },
    {
        "value": "NOT DELIVERED",
        "label": "NON CONSEGNATO"
    }

]}`

export const companyList = `[
    {
        "value": "",
        "label": "Select Company"
    },
    {
        "value": "Brewery Roma",
        "label": "Brewery Roma"
    }
]`

export const companyShipToList = `{
    "data": [
        {
            "id": 1,
            "name": "Brewery Roma",
            "shipTo": [
                {
                    "shipToId": "27079813",
                    "shipToName": "27079813 PRAŽSKÝ GURMÁN s.r.o.",
                    "rpDetails": [
                        {
                            "rolename": "WHS Delegated owner",
                            "roleid": "1f7c66eb-8763-4a36-af4a-31b5ab407cd9",
                            "permission": "Home Page,My Profile,Marketing Material",
                            "roleType": "Basic",
                            "customerType": "WHS",
                            "possibleRoles": ""
                        }
                    ]
                }
            ]
        },
        {
            "id": 2,
            "name": "Restaurant 2",
            "shipTo": [
                {
                    "shipToId": "988186",
                    "shipToName": "0001139990 ŠVEJK RESTAURANT PRAGUE INN ",
                    "rpDetails": [
                        {
                            "rolename": "WHS Common user",
                            "roleid": "e2f6d4ce-82f1-4cc9-87eb-ad0759563149",
                            "permission": "Home Page,My Profile,Marketing Material",
                            "roleType": "Basic",
                            "customerType": "WHS",
                            "possibleRoles": ""
                        },
                        {
                            "rolename": "WHS Delegated owner",
                            "roleid": "1f7c66eb-8763-4a36-af4a-31b5ab407cd9",
                            "permission": "Home Page,My Profile,Marketing Material",
                            "roleType": "Basic",
                            "customerType": "WHS",
                            "possibleRoles": ""
                        }
                    ]
                },
                {
                    "shipToId": "226739",
                    "shipToName": "0001149991 PUOR U VEJVODŮ ",
                    "rpDetails": [
                        {
                            "rolename": "WHS Owner",
                            "roleid": "13c67a05-2337-4583-a056-7777b73703da",
                            "permission": "Home Page,My Profile,Marketing Material",
                            "roleType": "Basic",
                            "customerType": "WHS",
                            "possibleRoles": ""
                        }
                    ]
                }
            ]
        }
    ]
}`

export const userDetailsShipTo = `{
    "id": "dbef5afe7a9e4bd6b0530ac74c855121",
    "firstName": "nikhil",
    "lastName": "babu",
    "email": "nikhil.babu@yopmail.com",
    "phone": "911911911556",
    "isActive": true,
    "termsAgreed": false,
    "createdAt": "2023-01-05T05:54:35.972Z",
    "permissionSchema": [
        {
            "shipToId": "27079813",
            "shipToName": "SPORTOVNÍ",
            "companyName": "Bre-Rom123",
            "rpDetails": [
                {
                    "rolename": "WHS Warehouse man",
                    "roleid": "e0259bdc-ec4d-42e0-9622-5f2a22c46dbe",
                    "permission": "Home Page,My Profile,Reporting,My Documents,Support,Accademy První u Zdroje",
                    "roleType": "Basic",
                    "customerType": "WHS",
                    "possibleRoles": "WHS Accountant,WHS Common user"
                }
            ]
        },
        {
            "shipToId": "1580455",
            "shipToName": "Jeremiášova",
            "companyName": "Bre-Rom123",
            "rpDetails": [
                {
                    "rolename": "WHS Accountant",
                    "roleid": "82d935b7-c8dc-4be1-89e6-ff44f25ee38c",
                    "permission": "Home Page,My Profile,Reporting,My Documents,Marketing Material,Support,Accademy První u Zdroje",
                    "roleType": "Basic",
                    "customerType": "WHS",
                    "possibleRoles": "WHS Accountant,WHS Common user"
                }
            ]
        },
        {
            "shipToId": "226581",
            "shipToName": "BAŽANTÍ",
            "companyName": "Restraunt2",
            "rpDetails": [
                {
                    "rolename": "WHS Common user",
                    "roleid": "82d935b7-c8dc-4be1-89e6-ff44f25ee38c",
                    "permission": "Home Page,Reporting,My Orders,News & Articless,Support,Accademy První u Zdroje",
                    "roleType": "Basic",
                    "customerType": "WHS",
                    "possibleRoles": "WHS Accountant,WHS Common user"
                }
            ]
        }
    ]
}`

export const powerBIToken = `{
    "id": "f1530029-c2f2-48aa-8746-968700c84088",
    "embedUrl": "https://app.powerbi.com/reportEmbed?reportId=f1530029-c2f2-48aa-8746-968700c84088&groupId=3271065b-94d7-40e4-88ac-c544baff8901&w=2&config=eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1JLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJlbWJlZEZlYXR1cmVzIjp7Im1vZGVybkVtYmVkIjp0cnVlLCJ1c2FnZU1ldHJpY3NWTmV4dCI6dHJ1ZX19",
    "pageName": null,
    "embedToken": {
        "token": "H4sIAAAAAAAEAB2Tx66kVgAF_-VtsURqoLE0C8Il5ww7cs7QNFj-d894X9KRqnT--bHSe5jT4ufvnz1E4wcec3xwXo6DTVuc5hpnh5HOk74E-4K3PfOJlAm0CPXraNBBEzpiDLTVfIiHrl8y7PrbbkJ0r5JZZNZSGyiWptfILeU363iISzgVrBFKeXX8FkUY7n9XJZMPYl6ruSBNF3dbrsfoG2d9S8lphAxNQraqu-jfVJvlwFK7zY7lal7puSlnEL_7ExaUzGinEcLTz33QW1s094gHjFIxd7zPL4mQi1YlbD7EoVOokNpsbWfBi2yxvm3Mzp2b9WPu9sbTaSKuD1L61qUleOlm2D-PKK-kcVTv1GGwxz-W3UvxNmY84xRPxG9U9QKOAmQHarvts_r35wXcIaSIR3xK_EMbH28jMAeY6W7dZ2WolI6PS99AFvPYgOrqpgMG9OnraqlAc8ZAAZyHIimmXPEUVw0Hc4J6KC1Uq4fJt2HszHaeSzGL5S_Epvi3LuIwyZhsOM7cSYvv2NLNrtuFjT6Bg3a5NZImR_zem-_y0I6BZziMHBTFZTusRj9ehUlhYg3aZEZ-lfqom8fRWVkn393c4IAxeDxpW8cVWTfxO-MW-UW1ryZ9lHmGWe_D0b4B5DwiQ_0kleSk-HijEgahaT5IwQX2BSW9qTJwh2k9sFyJxXK0Ooioern0dWvPtN6GLRjbAl7joX77KUq_w0Td_GO3WAuofpAeGpPURs9Z1Z96U0Sqw_BQ0G1cj4BZ6ix9tPomxR5B5LOk3-Lf2Z98KYpcJ4miXZmk--bELdp9_qimPL1CIZygXNV1mhcTkYxE5YQoENCFgNkhzr_rwVfpI5Sg-DmpiDvorNM_GTebW8drpVLIB7eNuwtokdZXlEOscuszSsSFISU01k7060UgTwzAJyZnJE6EqYzCDmnHJdKctwOPkVrg5MI_MEsSA4IHJ6tHbrf8_PXDbfdyzGp5_74jKN_NkXsm6lAhLUkjAUoriC2L6DsxZ98uz7BZjzFE1DTCN02wfCOG-bBA7IZmZl6Sg15sT68kV0DOvSX05MLEu0W8wg7vPXlirDev82a2C4n6WgtNeYdnqkjQEfryZd6MC0P3I3-zL9R8dYtfN0Vkw0z8zkKDLCsm6LdSYx3dUa0xJsg5Ol45EWLzV1hQ_8Qt2RK4neF4eTfOSW9RJIQs_KpL8vTjgya1nuxhCyiOKRZ8K9Xi119XKzjwk0O13uU-s2rlmnt_xCBGqJjwEm9jHKbYZHdRHPWV958t1T4-AE5xPu8i8vV6CGWT3t-O8bXSq8teyER9OesKXRRXoY0lapllfv36o_lemnKTg9-WZ0Rz3F4g4ydZus7vIJrVcvt_ym3rKT3OrfyDYW-HzjKTgt39GStNvey9QW2jEqoUKdzdV6E0vi-F3DuYx9FAhOeEL8gPPT9j5kJohPFOJYs1Hbmjqlg3dMXzMg8vLNKTTR_HdnBfdRsXyfDYSDAAkjJqsqtvS23XiboEfoc7OOaRQoxg7b2QEzqFOytx3GecMg-ollPTJPTmPNILDndcXdLDrwQKtzr2NpYWufJOGSEoGWFsUA9eTyglsuBgYx9cczE80FjAynBWkp_SVHwhSqQRHUGfnv-BeDYRHDyvqnwU3M0JIbDWNjTbenOe32eQX3Z5KanfRYnWCBE0OzaJmqBwGNMnAC48B_GtkxYQ-BapHZ9ysgOkL70Y858Y__4HL2CEUoIGAAA=.eyJjbHVzdGVyVXJsIjoiaHR0cHM6Ly9XQUJJLU5PUlRILUVVUk9QRS1JLVBSSU1BUlktcmVkaXJlY3QuYW5hbHlzaXMud2luZG93cy5uZXQiLCJleHAiOjE2NzY3MDY1MDMsImFsbG93QWNjZXNzT3ZlclB1YmxpY0ludGVybmV0Ijp0cnVlfQ==",
        "tokenId": "36fb9b88-0a94-4540-a327-e827421d3b76",
        "expiration": "2023-02-18T07:48:23Z"
    },
    "minutesToExpiration": 45,
    "errorMessage": null
}`
