import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { DocumentActionType } from '@/store/modules/documents/interfaces/action-type'
import { DocumentMutationType } from '@/store/modules/documents/interfaces/mutation-type'
import { IDocumentsState } from '@/store/modules/documents/interfaces/IDocumenstState'
import { IDocumentsMutations } from '@/store/modules/documents/interfaces/IDocumentsMutations'
import { IDocument } from '@/store/modules/documents/interfaces/api/IDocumentResponse'
import { documentsAPI } from '@/store/modules/documents/api/documents-api'
import { IDocumentRequest } from '@/store/modules/documents/interfaces/api/IDocumentRequest'
import { IDocumentsActions } from '@/store/modules/documents/interfaces/IDocumentsActions'
import { IDropdownItems } from '@/store/modules/common/interface/IDropdownItems'

const state = (): IDocumentsState => ({
  documents: [],
  documentTypes: [],
  total: 0,
  loading: false,
  errors: null
})

const mutations: MutationTree<IDocumentsState> & IDocumentsMutations = {
  [DocumentMutationType.SET_LOADING] (state: IDocumentsState, payload: boolean) {
    state.loading = payload
  },
  [DocumentMutationType.SET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = [...state.documents, ...payload]
  },
  [DocumentMutationType.RESET_DOCUMENTS] (state: IDocumentsState, payload: IDocument[]) {
    state.documents = payload
  },
  [DocumentMutationType.SET_TOTAL] (state: IDocumentsState, payload: number) {
    state.total = payload
  },
  [DocumentMutationType.SET_ERROR] (state: IDocumentsState, payload: any) {
    state.errors = payload
  },
  [DocumentMutationType.SET_DOCUMENT_TYPE] (state: IDocumentsState, payload: any) {
    state.documentTypes = payload
  }
}

const actions: ActionTree<IDocumentsState, IRootState> & IDocumentsActions = {
  async [DocumentActionType.FETCH_DOCUMENTS] ({ commit }, dto: IDocumentRequest) {
    commit(DocumentMutationType.SET_LOADING, true)
    try {
      const { reset = false, ...restDto } = { ...dto }

      await documentsAPI.getDocuments(restDto).then((response) => {
        if (reset) {
          commit(DocumentMutationType.RESET_DOCUMENTS, [])
        }
        // TODO need to remove the condition as we added this for removing the junk data for now
        if (response?.documentList?.length > 1) {
          const lfmt = response?.documentList?.find((document: any) => document?.link?.includes('lfmt') && (document?.documentType?.includes('Invoice') || document?.documentType?.includes('Faktura')))
          if (lfmt) {
            const result = response?.documentList?.filter((document: any) => document?.link?.includes('lfmt') || (document?.documentType?.includes('Invoice') || document?.documentType?.includes('Faktura')))
            commit(DocumentMutationType.SET_DOCUMENTS, result)
          } else {
            commit(DocumentMutationType.SET_DOCUMENTS, response.documentList)
          }
        } else {
          commit(DocumentMutationType.SET_DOCUMENTS, response.documentList)
        }
        commit(DocumentMutationType.SET_TOTAL, response.total)
        commit(DocumentMutationType.SET_LOADING, false)
      })
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
      commit(DocumentMutationType.SET_LOADING, false)
    }
  },
  async [DocumentActionType.FETCH_DOCUMENT_TYPES] ({ commit }) {
    try {
      await documentsAPI.getDocumentTypes().then((response) => {
        let results: IDropdownItems[] = []
        if (Array.isArray(response) && response.length > 0) {
          results = response.map((d) => {
            return {
              label: d.value,
              value: d.key
            }
          })
        }
        commit(DocumentMutationType.SET_DOCUMENT_TYPE, results)
      })
    } catch (error) {
      commit(DocumentMutationType.SET_ERROR, error)
    }
  }
}

export default {
  namespaced: true,
  state,
  mutations,
  actions
}
