import gql from 'graphql-tag'

export const deleteSavedOrder = gql`
query ($cart_id: String!) {
  deleteSavedOrder(
    cart_id: $cart_id
  ) {
    message,
    status
  }
}`
