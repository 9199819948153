import gql from 'graphql-tag'

export const fetchUpdatePricesMutation = gql(`
mutation (
    $cart_id: String!, $delivery_date: String!, $date_popup: String!, $customer_order_number: String, $note_for_driver: String, $payment_method: String!,
    $outlet_order_type: String!, $delivery_method: String!, $empties_return: String!, $empties_data: [EmtiesArray], $no_empties_return: String!
) {
  updatePrices(
    input: {
      cart_id: $cart_id
      delivery_date: $delivery_date
      date_popup: $date_popup
      customer_order_number: $customer_order_number
      payment_method: $payment_method
      outlet_order_type: $outlet_order_type
      delivery_method: $delivery_method
      note_for_driver:  $note_for_driver
      empties_return: $empties_return
      empties_data: $empties_data
      no_empties_return: $no_empties_return
    }
  ) {
    success,
    message,
    can_proceed,
    status_message,
    minicart_status_message,
    payment_method,
    delivery_date,
    delivery_date_expired,
    order_type,
    delivery_method,
    ship_to_id,
    order_items{
        name,
        sku,
        packaging,
        your_price,
        volume,
        is_wishlist,
        image_main,
        row_total
        base_price
    }
    empties_arr {
      empties {
        packaging_name
        packaging_issued
        number_of_units
        total
        packaging_volume
      }
    }
    cart_blga_arr {
      packaging_name
      packaging_volume
      number_of_units
      unit_of_measure
      total
    }
    total_base_price,
    total_your_price,
    total_quantity
    total_empties_deposit
   }
  }
`)
