import gql from 'graphql-tag'

export const fetchCartValuesQuery = gql(`
query ($cart_id: String!) {
  cart(
      cart_id: $cart_id
  )  {
    delivery_date
    delivery_method
    id
    total_quantity
    multi_deposit
    ship_to_id
    grand_total_your_price_details
    grand_total_multi_deposit
    bundle_your_price_sum_grand_total
    quantity_totals
    outlet_details {
      payer_name
      outlet_name
      street
      city
      country
      province
      postalcode
      sap_id
    }
    progress_bar_data {
      isFullTruck
      iMinimumOrderPallet
      iMaximumOrderPallet
      iMinimumOrderWeight
      iMaximumOrderWeight
      iMaximumOrderCash
      sOutletDeliveryMethod
      iTotalCartWeight
      iTotalCartPalletQty
    }
  }
}
`)
