import { ActionTree, MutationTree } from 'vuex'
import { IRootState } from '@/store'
import { InvoicesOlsaActionType } from '@/store/modules/invoicesOlsa/interfaces/action-type'
import { InvoicesOlsaMutationType } from '@/store/modules/invoicesOlsa/interfaces/mutation-type'
import { IInvoicesOlsaState } from '@/store/modules/invoicesOlsa/interfaces/IInvoicesOlsaState'
import { IInvoicesOlsaMutations } from '@/store/modules/invoicesOlsa/interfaces/IInvoicesOlsaMutations'
import { IInvoiceOlsa } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaResponse'
import { invoicesOlsaAPI } from '@/store/modules/invoicesOlsa/api/invoicesOlsa-api'
import { IInvoicesOlsaRequest } from '@/store/modules/invoicesOlsa/interfaces/invoicesOlsa/IInvoicesOlsaRequest'
import { IInvoicesOlsaActions } from '@/store/modules/invoicesOlsa/interfaces/IInvoicesOlsaActions'
import { ISelectedInvoiceOlsaResponse } from '@/store/modules/invoicesOlsa/interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaResponse'
import { ISelectedInvoiceOlsaRequest } from '@/store/modules/invoicesOlsa/interfaces/selectedInvoiceOlsa/ISelectedInvoiceOlsaRequest'

const state = (): IInvoicesOlsaState => ({
  invoicesOlsa: [],
  selectedInvoiceOlsa: null,
  loading: false,
  errors: null
})

const mutations: MutationTree<IInvoicesOlsaState> & IInvoicesOlsaMutations = {
  [InvoicesOlsaMutationType.SET_LOADING] (state: IInvoicesOlsaState, payload: boolean) {
    state.loading = payload
  },
  [InvoicesOlsaMutationType.SET_INVOICES_OLSA] (state: IInvoicesOlsaState, payload: IInvoiceOlsa[]) {
    state.invoicesOlsa = payload
  },
  [InvoicesOlsaMutationType.SET_SELECTED_INVOICE_OLSA] (state: IInvoicesOlsaState, payload: ISelectedInvoiceOlsaResponse) {
    state.selectedInvoiceOlsa = payload
  },
  [InvoicesOlsaMutationType.SET_ERROR] (state: IInvoicesOlsaState, payload: any) {
    state.errors = payload
  }
}

const actions: ActionTree<IInvoicesOlsaState, IRootState> & IInvoicesOlsaActions = {
  async [InvoicesOlsaActionType.FETCH_INVOICES_OLSA] ({ commit }, dto: IInvoicesOlsaRequest) {
    commit(InvoicesOlsaMutationType.SET_LOADING, true)
    try {
      const response = await invoicesOlsaAPI.getInvoicesOlsa(dto)
      commit(InvoicesOlsaMutationType.SET_INVOICES_OLSA, response.dataArchive)
    } catch (error) {
      commit(InvoicesOlsaMutationType.SET_ERROR, error)
      commit(InvoicesOlsaMutationType.SET_LOADING, false)
    }
  },
  async [InvoicesOlsaActionType.FETCH_SELECTED_INVOICE_OLSA] ({ commit }, dto: ISelectedInvoiceOlsaRequest) {
    commit(InvoicesOlsaMutationType.SET_LOADING, true)
    try {
      const response = await invoicesOlsaAPI.getSelectedInvoiceOlsa(dto)
      commit(InvoicesOlsaMutationType.SET_SELECTED_INVOICE_OLSA, response)
    } catch (error) {
      commit(InvoicesOlsaMutationType.SET_ERROR, error)
      commit(InvoicesOlsaMutationType.SET_LOADING, false)
    }
  }
}

export default {
  state,
  mutations,
  actions
}
