export const constants = {
  menuWithoutLogin: process.env.VUE_APP_MENU_WITHOUT_LOGIN?.split(','),
  saml: {
    eLearningURL: process.env.VUE_APP_CDC_ELEARNING,
    pubMenuURL: process.env.VUE_APP_CDC_PUBMENU,
    empties: process.env.VUE_APP_EMPTIES_REDIRECT_URL
  },
  links: {
    termsOfUse: {
      sk: process.env.VUE_APP_FOOTER_TC_SK,
      en: process.env.VUE_APP_FOOTER_TC_EN,
      cs: process.env.VUE_APP_FOOTER_TC_CZ
    },
    marketingMaterialGuidelineURL: process.env.VUE_APP_MM_GUIDELINE,
    social: {
      fb: process.env.VUE_APP_SOCIAL_FB,
      linkedIn: process.env.VUE_APP_SOCIAL_LINKEDIN,
      insta: process.env.VUE_APP_SOCIAL_INSTA
    },
    footer: {
      corporateInformation: process.env.VUE_APP_FOOTER_CORPORATE_INFORMATION,
      privacyPolicy: {
        sk: '/files/sk/privacy_policy_sk.pdf', // TODO temporary, will be stored elsewhere, then remove the pdf
        default: process.env.VUE_APP_FOOTER_PRIVACY_POLICY
      },
      cookies: {
        sk: process.env.VUE_APP_FOOTER_COOKIES_SK,
        cs: process.env.VUE_APP_FOOTER_COOKIES_CZ,
        en: process.env.VUE_APP_FOOTER_COOKIES_EN,
        it: process.env.VUE_APP_FOOTER_COOKIES,
        de: process.env.VUE_APP_FOOTER_COOKIES
      },
      corporateGovernance: process.env.VUE_APP_FOOTER_CORPORATE_GOVERNANCE,
      Fb: process.env.VUE_APP_FB,
      Instagram: process.env.VUE_APP_INSTAGRAM,
      Linkedin: process.env.VUE_APP_LINKEDIN,
      Napivosrozumem: process.env.VUE_APP_BRAND_NAPIVOSROZUMEM,
      brandPilsnerCs: process.env.VUE_APP_BRAND_PILSNER_CS,
      brandGambrinusCs: process.env.VUE_APP_BRAND_GAMBRINUS_CS,
      brandKozelCs: process.env.VUE_APP_BRAND_KOZEL_CS,
      brandRadegastCs: process.env.VUE_APP_BRAND_RADEGAST_CS,
      brandBirellCs: process.env.VUE_APP_BRAND_BIRELL_CS,
      brandExcellentCs: process.env.VUE_APP_BRAND_EXCELLENT_CS,
      brandFriscoCs: process.env.VUE_APP_BRAND_FRISCO_CS,
      brandSarisSk: process.env.VUE_APP_BRAND_SARIS_SK,
      brandPilsnerSk: process.env.VUE_APP_BRAND_PILSNER_SK,
      brandRadegastSk: process.env.VUE_APP_BRAND_RADEGAST_SK,
      brandKozelSk: process.env.VUE_APP_BRAND_KOZEL_SK,
      brandGambrinusSk: process.env.VUE_APP_BRAND_GAMBRINUS_SK,
      brandBirellSk: process.env.VUE_APP_BRAND_BIRELL_SK
    }
  },
  application: {
    theme: <'prazdroj' | 'peroni'> process.env.VUE_APP_THEME,
    contactUsEmail: process.env.VUE_APP_CONTACT_US_MAIL,
    contactUsEmailSK: process.env.VUE_APP_CONTACT_US_MAIL_SK,
    contactUs: process.env.VUE_APP_CONTACT_US,
    contactUsSK: process.env.VUE_APP_CONTACT_US_SK,
    pageTitle: process.env.VUE_APP_DEFAULT_PAGE_TITLE,
    powerBiSchema: process.env.VUE_APP_ABOUT_POWER_BI_SCHEMA
  },
  gigyaConfig: {
    screenSet: process.env.VUE_APP_SCREENSET,
    startScreen: process.env.VUE_APP_STARTSCREEN,
    forgotScreen: process.env.VUE_APP_FORGOTSCREEN,
    resetScreen: process.env.VUE_APP_RESETSCREEN
  },
  gtm: {
    containerId: process.env.VUE_APP_GTM_CONTAINER_ID
  }
} as const
