import gql from 'graphql-tag'

export const fetchProductsToCartMutation = gql(`
mutation ($cart_id: String!, $quantity: Float!, $sku: String!, $number_of_layer: Int!, $number_of_pallet: Int!, $selected_options: [ID]) {
  addProductsToCart(
    cartId: $cart_id
    cartItems: [
      {
        quantity: $quantity
        sku: $sku,
        number_of_layer: $number_of_layer,
        number_of_pallet: $number_of_pallet,
        selected_options: $selected_options
      }
    ]
  ) {
      user_errors {
        code
        message
      }
    }
  }
`)
