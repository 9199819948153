import { ActionContext } from 'vuex'
import { IRootState } from '@/store'

export enum HelpPagesMutationType {
  SET_LOADING = 'SET_LOADING',
  SET_ALL_HELP_PAGES = 'SET_ALL_HELP_PAGES'
}

export enum HelpPagesActionType {
  FETCH_ALL_HELP_PAGES = 'FETCH_ALL_HELP_PAGES'
}

export interface IHelpPages {
  title: string
  body: string
  file: {
    link: string
  }
  page: number
}

export interface IFetchHelpPagesRequest {
  limit?: number
  offset?: number
  market?: string
  userType?: string
  language?: string
  sortType?: string
}

export interface IFetchHelpPagesResponse {
  total: number
  offset: number
  limit: number
  results: Array<IHelpPages>
}

export interface IHelpPagesState {
  helpPages: Array<IHelpPages>
  loading: boolean
}

export interface IHelpPagesMutations<S = IHelpPagesState> {
  [HelpPagesMutationType.SET_LOADING](state: S, payload: boolean): void
  [HelpPagesMutationType.SET_ALL_HELP_PAGES](state: S, payload: Array<IHelpPages>): void
}

export type AugmentedHelpPagesActionsType = {
  commit<K extends keyof IHelpPagesMutations>(
    key: K,
    payload: Parameters<IHelpPagesMutations[K]>[1]
  ): ReturnType<IHelpPagesMutations[K]>
} & Omit<ActionContext<IHelpPagesState, IRootState>, 'commit'>

export interface IHelpPagesActions {
  [HelpPagesActionType.FETCH_ALL_HELP_PAGES](
    { commit }: AugmentedHelpPagesActionsType,
    payload: IFetchHelpPagesRequest
  ): void
}
