import gql from 'graphql-tag'

export const fetchCategoriesQuery = gql`
query ($url_key: String = "Default Category") {
  categoryList(
    filters: {
      name: {
        match: $url_key
      } 
    }
  ) {
      name
      url_key
      uid
      url_path
      level
      children_count
      product_count
      children {
        uid
        level
        name
        path
        url_path
        url_key
        product_count
        children {
          uid
          level
          name
          path
          url_path
          url_key
          product_count
        }
      }
    }
  }
`
