import { createElementVNode as _createElementVNode, openBlock as _openBlock, createElementBlock as _createElementBlock, createCommentVNode as _createCommentVNode, renderSlot as _renderSlot, normalizeClass as _normalizeClass } from "vue"

const _hoisted_1 = { key: 0 }
const _hoisted_2 = { class: "modal-overflow-wrapper" }

export function render(_ctx: any,_cache: any,$props: any,$setup: any,$data: any,$options: any) {
  return (_ctx.showModal)
    ? (_openBlock(), _createElementBlock("div", _hoisted_1, [
        _createElementVNode("div", {
          class: "overlay",
          onClick: _cache[0] || (_cache[0] = 
        () => {
          if (_ctx.backdropDismiss) _ctx.close()
        }
      )
        }),
        _createElementVNode("div", {
          class: _normalizeClass(['modal', _ctx.useOverflowStyle ? 'overflow-y' : ''])
        }, [
          (_ctx.showCloseButton)
            ? (_openBlock(), _createElementBlock("button", {
                key: 0,
                class: "modal-close",
                onClick: _cache[1] || (_cache[1] = () => _ctx.close())
              }))
            : _createCommentVNode("", true),
          _createElementVNode("div", _hoisted_2, [
            _renderSlot(_ctx.$slots, "default")
          ])
        ], 2)
      ]))
    : _createCommentVNode("", true)
}