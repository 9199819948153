import {
  ICategory,
  ICreateTicketRequest,
  IFetchAttachmentResponse,
  IFetchCommentResponse,
  IFetchTicketsRequest,
  IFetchTicketsResponse, IFetchUpdateTicketRequest,
  ISubCategory
} from '@/store/modules/tickets/interfaces'
import { AxiosResponse } from 'axios'
import { instance } from '@/store/api-instance'
import { constants } from '@/constants'

export const ticketsAPI = {
  async fetchAllTickets (dto: IFetchTicketsRequest): Promise<IFetchTicketsResponse> {
    const response: AxiosResponse = await instance.get<IFetchTicketsResponse>('tickets/GetAllTickets', { params: dto })
    return response.data
  },
  async fetchCategories (): Promise<ICategory[]> {
    const response: AxiosResponse<ICategory[]> = await instance.get('tickets/GetCategories')
    return response.data
  },
  async fetchSubCategories (id: string): Promise<ISubCategory[]> {
    const response: AxiosResponse<ISubCategory[]> = await instance.get(`tickets/GetSubCategoriesByCategoryId/${id}`)
    return response.data
  },
  async fetchAllSubCategories (): Promise<ISubCategory[]> {
    const response: AxiosResponse<ISubCategory[]> = await instance.get('tickets/GetAllSubCategories')
    return response.data
  },
  async fetchCreateComment (dto: Omit<IFetchUpdateTicketRequest, 'attachments'>): Promise<IFetchCommentResponse> {
    const response: AxiosResponse<IFetchCommentResponse> = await instance.post('tickets/CreateComment', { ...dto })
    return response.data
  },
  async fetchUploadAttachment (dto: Pick<IFetchUpdateTicketRequest, 'attachments' | 'ticketId'>): Promise<IFetchAttachmentResponse> {
    const response: AxiosResponse<IFetchAttachmentResponse> = await instance.post(`tickets/UploadAttachmentsByTicketId?ticketId=${dto.ticketId}`, dto.attachments)
    return response.data
  },
  async fetchCommentsByTicket (id: string, limit = 100): Promise<IFetchCommentResponse> {
    try {
      const response: AxiosResponse<IFetchCommentResponse> = await instance.get(`tickets/GetCommentsByTicketId/${id}?limit=${limit}`)
      return response.data
    } catch (err) {
      console.log(err)
      return { commentsList: [] }
    }
  },
  async fetchAttachmentsByTicket (id: string): Promise<IFetchAttachmentResponse> {
    try {
      const response: AxiosResponse<IFetchAttachmentResponse> = await instance.get(`tickets/GetAttachmentsByTicketId/${id}`)
      return response.data
    } catch (err) {
      console.log(err)
      return { attachmentList: [] }
    }
  },
  async createTicket (dto: ICreateTicketRequest): Promise<any> {
    const market = (constants.application.theme === 'peroni') ? 'IT' : 'CZ'
    const response: AxiosResponse = await instance.post<any>(`tickets/CreateTicket?market=${market}`, dto)
    return response.data
  }
}

// user need tto be CDC user ID
