
import { defineComponent } from 'vue'

const isEqualObject = (object1: { [key: string]: any }, object2: { [key: string]: any }): boolean => {
  const keys1 = Object.keys(object1)
  const keys2 = Object.keys(object2)

  if (keys1.length !== keys2.length) return false

  for (const key of keys1) {
    if (object1[key] !== object2[key]) return false
  }

  return true
}

export default defineComponent({
  name: 'ResizeObserver',

  emits: ['resize'],

  props: {
    debounce: {
      type: Number,
      default: 100
    }
  },

  data () {
    return {
      observer: undefined as ResizeObserver | undefined,
      timeout: undefined as ReturnType<typeof setTimeout> | undefined,
      size: { width: -1, height: -1 }
    }
  },

  mounted () {
    this.observer = new ResizeObserver(this.trigger)
    this.observer.observe(this.$el.parentNode)
    this.onResize()
  },

  beforeUnmount () {
    if (this.timeout) clearTimeout(this.timeout)

    if (this.observer && this.$el.parentNode) {
      this.observer.unobserve(this.$el.parentNode)
    }
  },

  methods: {
    trigger (now: any) {
      if (now === true || this.debounce === 0) {
        this.onResize()
      } else if (!this.timeout) {
        this.timeout = setTimeout(this.onResize, this.debounce)
      }
    },

    onResize () {
      this.timeout = undefined

      if (!this.$el || !this.$el.parentNode) {
        return
      }

      const size = {
        width: this.$el.parentNode.offsetWidth,
        height: this.$el.parentNode.offsetHeight
      }

      if (!isEqualObject(size, this.size)) {
        this.size = size
        this.$emit('resize', this.size)
      }
    }
  }
})
