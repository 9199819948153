import gql from 'graphql-tag'

export const fetchPlaceOrderMutation = gql`
mutation ($cart_id: String!) {
  placeOrder(
    input: {
        cart_id: $cart_id
        }
    )
    {
        order {
            order_number
             customer_order_number
             note_for_driver
             sap_id
        }
    }
}

`
