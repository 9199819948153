import type { Component } from 'vue'
import ConfirmModal from '@/components/common/ConfirmModal.vue'
import { setLoadingPaused } from '@/mixins/safeMethods'

interface ShowComponentDialogOptions {
  component: Component | null
  props?: Record<string, any>
  listeners?: Record<string, any>
  showCloseButton?: boolean
  backdropDismiss?: boolean
}

interface ShowConfirmModalOptions {
  title?: string
  message?: string
  icon?: string
  confirm?: string
  cancel?: string
}

class RError extends Error {
  isSafeAbortError? = false
}

class SafeAbortError extends RError {
  constructor (message: string) {
    super(message)
    this.isSafeAbortError = true
  }
}

export interface ShowComponentDialogHandlerOptions extends ShowComponentDialogOptions {
  id: string
  onClose: () => void
}

export default {
  openDialogCount: 0,
  showModalHandler: undefined as ((args: ShowComponentDialogHandlerOptions) => void) | undefined,

  async showModal ({ listeners: { onClose, ...listeners } = {}, ...args }: ShowComponentDialogOptions) {
    if (!this.showModalHandler) return

    try {
      if (this.openDialogCount === 0) {
        setLoadingPaused(true)
      }
      this.openDialogCount++
      const promise = new Promise((resolve) => {
        this.showModalHandler &&
          this.showModalHandler({
            ...args,
            listeners,
            id: 'modal-' + (this.openDialogCount + 1),
            onClose: () => {
              if (onClose) onClose()
              resolve(true)
            }
          })
      })
      return await promise
    } catch (e: any) {
      throw new Error(e.message)
    } finally {
      this.openDialogCount--
      if (this.openDialogCount === 0) {
        setLoadingPaused(false)
      }
    }
  },

  showConfirmModal (args: ShowConfirmModalOptions) {
    const promise = new Promise((resolve, reject) => {
      this.showModal({
        component: ConfirmModal,
        showCloseButton: false,
        backdropDismiss: false,
        props: args,
        listeners: {
          confirm: (data: any) => {
            resolve(data)
          },
          cancel: () => {
            reject(new SafeAbortError('User canceled the action'))
          }
        }
      })
    })
    return promise
  }
}
