import gql from 'graphql-tag'

export const fetchShippingAddressMutation = gql(`
mutation ($cart_id: String!, $shipping_addresses: [ShippingAddressInput]!) {
  setShippingAddressesOnCart(
    input: {
      cart_id: $cart_id
      shipping_addresses: $shipping_addresses
    }
  ) {
    cart {
      shipping_addresses {
        firstname
        lastname
        company
        street
        city
        region {
        code
        label
        }
        postcode
        telephone
        country {
        code
        label
        }
        pickup_location_code
        }
      }
    }
  }
`)
