
import { defineComponent, shallowRef } from 'vue'
import ModalComponent from '@/components/ModalComponent.vue'
import LoaderSpinner from '@/components/common/LoaderSpinner.vue'
import modals, { ShowComponentDialogHandlerOptions } from '@/utils/modals'
import loader from '@/utils/loader'

export default defineComponent({
  name: 'AppGlobalModal',

  components: {
    ModalComponent,
    LoaderSpinner
  },

  data () {
    return {
      modals: [] as any[],
      modalRefs: [] as any[],
      showLoader: false
    }
  },

  mounted () {
    const showModalHandler = (args: ShowComponentDialogHandlerOptions) => {
      const modal = {
        ...args,
        component: shallowRef(args.component),
        onClose: () => {
          this.modals = this.modals.filter((m) => m.id !== modal.id)
          args.onClose()
        }
      }

      this.modals.push(modal)
      this.$nextTick(() => {
        this.modalRefs[this.modalRefs.length - 1].open()
      })
    }

    const showLoaderHandler = (value: boolean) => {
      this.showLoader = value
    }

    modals.showModalHandler = showModalHandler
    loader.showLoaderHandler = showLoaderHandler
  },

  beforeUnmount () {
    modals.showModalHandler = undefined
  },

  beforeUpdate () {
    this.modalRefs = []
  },

  methods: {
    setModalRef (modal: InstanceType<typeof ModalComponent>) {
      this.modalRefs.push(modal as any)
    },

    closeModal () {
      this.modalRefs = this.modalRefs.filter((m) => m != null)
      this.modalRefs[this.modalRefs.length - 1].close()
    }
  }
})
