import { IMainState } from '@/store/modules/main/intefaces/IMainState'
import { MainMutationType } from '@/store/modules/main/intefaces/mutation-types'
import { RouteLocationNormalized } from 'vue-router'
import { IBannersConfig, IConfig, IHolidaysConfig, IPermissionsConfig } from './intefaces/IConfig'
import { IRootState } from '@/store'
import { ActionTree } from 'vuex'
import { IMainActions } from '@/store/modules/main/intefaces/IMainActions'
import { apolloClient } from '@/store/graphql.config'
import { MainActionType } from './intefaces/action-types'
import { fetchConfigQuery } from './queries/fetchConfigQuery'
import { IFetchConfigResponse } from './intefaces/fetchConfig/IFetchConfigResponse'
import { fetchBannersConfigQuery } from './queries/fetchBannersConfigQuery'
import { fetchHolidaysConfigQuery } from './queries/fetchHolidaysConfigQuery'
import { IFetchHolidaysConfigResponse } from './intefaces/fetchHolidaysConfig/IFetchHolidaysConfigResponse'
import { IFetchBannersConfigResponse } from './intefaces/fetchBannersConfig/IFetchHolidaysConfigResponse'
import { fetchPermissionsQuery } from './queries/fetchPermissionsQuery'
import { IFetchPermissionsResponse } from './intefaces/fetchPermissions/IFetchPermissionsResponse'
import { IFetchOrderableUnitsRequest } from './intefaces/fetchOrderableUnitsConfig/IFetchOrderableUnitsRequest'
import { IFetchOrderableUnitsResponse } from './intefaces/fetchOrderableUnitsConfig/IFetchOrderableUnitsResponse'
import { fetchOrderableUnitsQuery } from './queries/fetchOrderableUnitsQuery'
import { getOrderTypeForUnitsConfig } from '@/utils/transportTypes'
import { IOrderableUnits, OrderableUnit, ShowOrderableUnits } from './intefaces/fetchOrderableUnitsConfig/IOrderableUnits'

const state = (): IMainState => ({
  ssrRendered: false,
  routeLoading: false,
  pageNotFound: false,
  history: [],
  config: {
    maintenance: false
  },
  bannersConfig: {
    banner_rotation: 0
  },
  holidaysConfig: {
    holidays: []
  },
  permissions: [] as IPermissionsConfig[],
  orderableUnits: {
    loaded: false,
    units: []
  },
  route: ''
})

const getters = {
  getLastRoute (state: IMainState) {
    return state.history[state.history.length - 1]
  },
  getPermissions (state: IMainState) {
    return function () {
      return state.permissions
    }
  },
  getOrderableUnits (state: IMainState): ShowOrderableUnits {
    return {
      loaded: state.orderableUnits.loaded,
      showPackaging: state.orderableUnits.units.includes(OrderableUnit.PACKAGING),
      showLayer: state.orderableUnits.units.includes(OrderableUnit.LAYERS),
      showPallets: state.orderableUnits.units.includes(OrderableUnit.PALLETS)
    }
  },
  getRoute (rootState: any) {
    return rootState.route
  }
}

const mutations = {
  [MainMutationType.SET_SSR_RENDERED] (state: IMainState, payload: boolean): void {
    state.ssrRendered = payload
  },
  [MainMutationType.SET_ROUTE_LOADING] (state: IMainState, payload: boolean): void {
    state.routeLoading = payload
  },
  [MainMutationType.SET_PAGE_NOT_FOUND] (state: IMainState, payload: boolean): void {
    state.pageNotFound = payload
  },
  [MainMutationType.SET_HISTORY] (state: IMainState, payload: RouteLocationNormalized): void {
    if (state.history.length >= 10) {
      state.history.splice(0, 1)
    }
    state.history.push(payload)
  },
  [MainMutationType.SET_CONFIG] (state: IMainState, payload: IConfig): void {
    state.config = payload
  },
  [MainMutationType.SET_BANNERS_CONFIG] (state: IMainState, payload: IBannersConfig): void {
    state.bannersConfig = payload
  },
  [MainMutationType.SET_HOLIDAYS_CONFIG] (state: IMainState, payload: IHolidaysConfig): void {
    state.holidaysConfig = payload
  },
  [MainMutationType.SET_PERMISSIONS] (state: IMainState, payload: IPermissionsConfig[]): void {
    state.permissions = payload
  },
  [MainMutationType.SET_ORDERABLE_UNITS_CONFIG] (state: IMainState, payload: IOrderableUnits): void {
    state.orderableUnits = payload
  },
  [MainMutationType.SET_ROUTE] (state: IMainState, payload: string): void {
    state.route = payload
  }
}

export const actions: ActionTree<IMainState, IRootState> & IMainActions = {
  async [MainActionType.FETCH_CONFIG] ({ commit }) {
    try {
      const { data } = await apolloClient.query<IFetchConfigResponse, object>({
        query: fetchConfigQuery
      })
      commit(MainMutationType.SET_CONFIG, data?.getPortalConfig?.maintenance)
    } catch (e: any) {
      console.warn(e)
    }
  },
  async [MainActionType.FETCH_BANNERS_CONFIG] ({ commit }) {
    try {
      const { data } = await apolloClient.query<IFetchBannersConfigResponse, object>({
        query: fetchBannersConfigQuery
      })
      commit(MainMutationType.SET_BANNERS_CONFIG, data?.getPortalConfig?.banner_rotation)
    } catch (e: any) {
      console.warn(e)
    }
  },
  async [MainActionType.FETCH_HOLIDAYS_CONFIG] ({ commit }) {
    try {
      const { data } = await apolloClient.query<IFetchHolidaysConfigResponse, object>({
        query: fetchHolidaysConfigQuery
      })
      commit(MainMutationType.SET_HOLIDAYS_CONFIG, data?.getPortalConfig?.holidays)
    } catch (e: any) {
      console.warn(e)
    }
  },
  async [MainActionType.FETCH_PERMISSIONS] ({ commit }) {
    try {
      const { data } = await apolloClient.query<IFetchPermissionsResponse, object>({
        query: fetchPermissionsQuery
      })
      commit(MainMutationType.SET_PERMISSIONS, JSON.parse(data?.getPortalConfig?.sections))
    } catch (e: any) {
      console.warn(e)
    }
  },
  async [MainActionType.FETCH_ORDERABLE_UNITS_CONFIG] ({ commit }, dto: IFetchOrderableUnitsRequest) {
    try {
      const { data } = await apolloClient.query<IFetchOrderableUnitsResponse, object>({
        query: fetchOrderableUnitsQuery,
        variables: {
          customerType: dto.customerType,
          orderType: getOrderTypeForUnitsConfig(dto.orderType)
        }
      })
      const orderableUnitState = { loaded: true, units: data?.getPortalConfig?.orderableunits.split(',') as unknown as OrderableUnit[] }
      commit(MainMutationType.SET_ORDERABLE_UNITS_CONFIG, orderableUnitState)
    } catch (e: any) {
      console.warn(e)
    }
  }
}

const MainModule = {
  namespaced: true,
  state,
  getters,
  mutations,
  actions
}

export default MainModule
