import gql from 'graphql-tag'

export const fetchAvailablePackagingQuery = gql`
 query ($sku: String, $brand_code: String) {
  getProductAvaliablePackaging(
    sku: $sku,
    sap_sub_brand_code: $brand_code
  ){
    type
    volume
    title
    your_price
    your_price_tax
    sku
  }
}
`
