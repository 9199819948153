import gql from 'graphql-tag'

export const fetchProductsAggregationsQuery = gql`
  query ($sku: [String]) {
    products(filter: { sku: { in: $sku } }) {
      aggregations {
        attribute_code
        count
        label
        options {
          label
          value
          count
        }
      }
    }
  }
`
